import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  getMembershipGroups,
  getAddressAutocompleteResults,
  getStripeIntentResult,
} from '@api/app';
import { search, updateContactDetails, getPaymentMethods } from '@api/contact';
import { fetchPaymentSchedule } from '@root/modules/donation/donation.slice';
import {
  AppState,
  ContactDetails,
  DonationAmtAndFreq,
  SelectedMembership,
  StripeIntent,
  PaymentMethod,
  AdditionalInformation,
} from './types';
import { AppThunk } from './AppThunk';

const appContainer = document.getElementById('root');
const clientCode = appContainer.dataset.clientcode;
const clientHomepage = appContainer.dataset.clienthomepage;
const sentryKey = appContainer.dataset.sentrykey;
const gtmId = appContainer.dataset.gtmid;

const initialState: AppState = {
  clientCode,
  clientHomepage,
  contactDetails: null,
  stripeIntent: null,
  donationAmtAndFreq: null,
  coverCosts: false,
  contactSignUp: null,
  acceptNotice: false,
  selectedPackageId: null,
  isLoading: false,
  isLoadingContactDetails: false,
  isLoadingPaymentMethods: false,
  updateCompleted: false,
  error: null,
  contactSearchError: null,
  selectedMembership: null,
  additionalInformation: null,
  selectedNewGift: false,
  paymentMethods: [],
  cancellationStatus: false,
  giftAidClaimAmount: 0,
  giftAidCheckbox: false,
  creditCardNo: null,
  expiryMM: null,
  expiryYY: null,
  cvcNo: null,
  membershipGroups: null,
  addressSuggestions: [null],
  sentryKey,
  stripeIntentResult: null,
  verificationAddressResult: null,
  gtmId,
  errorLogResult: null,
};

function startLoading(state: AppState) {
  state.isLoading = true;
}

function loadingFailed(state: AppState, action: PayloadAction<string>) {
  state.isLoading = false;
  state.error = action.payload;
}

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    getClientCodeStart: startLoading,
    getClientCodeSuccess(state, action: PayloadAction<string>) {
      state.clientCode = action.payload.toLowerCase();
      state.error = null;
      state.isLoading = false;
    },
    getClientCodeFailed: loadingFailed,
    getMembershipGroupsStart(state: AppState) {
      state.isLoadingContactDetails = true;
    },
    getMembershipGroupsSuccess(state: AppState, action: PayloadAction<any>) {
      state.membershipGroups = action.payload;
      state.isLoadingContactDetails = false;
    },
    getMembershipGroupsFailure(state: AppState, action: PayloadAction<string>) {
      state.error = action.payload;
      state.isLoadingContactDetails = false;
    },
    getContactDetailsStart(state: AppState) {
      state.isLoadingContactDetails = true;
    },
    getContactDetailsSuccess(
      state: AppState,
      action: PayloadAction<ContactDetails>
    ) {
      state.contactDetails = action.payload;
      state.isLoadingContactDetails = false;
      state.contactSearchError = null;
    },
    getContactDetailsFailure(state: AppState, action: PayloadAction<string>) {
      state.contactSearchError = action.payload;
      state.isLoadingContactDetails = false;
    },
    // -Pages/Donate - greg
    setDonationAmtAndFreq(
      state: AppState,
      action: PayloadAction<DonationAmtAndFreq>
    ) {
      state.donationAmtAndFreq = action.payload;
    },
    // Complete messaging
    setUpdateComplete(state, action: PayloadAction<boolean>) {
      state.updateCompleted = action.payload;
    },
    // -Pages/Payment
    setCoverCosts(state, action: PayloadAction<boolean>) {
      state.coverCosts = action.payload;
    },
    setSelectedPackageId(state, action: PayloadAction<string>) {
      state.selectedPackageId = action.payload;
    },
    setSelectedMembership(state, action: PayloadAction<SelectedMembership>) {
      state.selectedMembership = action.payload;
    },
    setAdditionalInformation(
      state,
      action: PayloadAction<AdditionalInformation>
    ) {
      state.additionalInformation = action.payload;
    },
    setSelectedNewGift(state, action: PayloadAction<boolean>) {
      state.selectedNewGift = action.payload;
    },
    getPaymentMethodsStart(state: AppState) {
      state.isLoadingPaymentMethods = true;
    },
    getPaymentMethodsSuccess(state, action: PayloadAction<PaymentMethod[]>) {
      state.paymentMethods = action.payload;
      state.error = null;
      state.isLoadingPaymentMethods = false;
    },
    getPaymentMethodsFailed(state) {
      state.paymentMethods = null;
      state.error = null;
      state.isLoadingPaymentMethods = false;
    },
    setCreditCard(state, action: PayloadAction<string>) {
      state.creditCardNo = action.payload;
    },
    setExpiryMM(state, action: PayloadAction<string>) {
      state.expiryMM = action.payload;
    },
    setExpiryYY(state, action: PayloadAction<string>) {
      state.expiryYY = action.payload;
    },
    setCancellationStatus(state, action: PayloadAction<boolean>) {
      state.cancellationStatus = action.payload;
    },
    setGiftAidClaimAmount(state, action: PayloadAction<number>) {
      state.giftAidClaimAmount = action.payload;
    },
    setGiftAidCheckbox(state, action: PayloadAction<boolean>) {
      state.giftAidCheckbox = action.payload;
    },
    setContactSignUp(state, action: PayloadAction<boolean>) {
      state.contactSignUp = action.payload;
    },
    setPaymentNotice(state, action: PayloadAction<boolean>) {
      state.acceptNotice = action.payload;
    },
    setAddressSuggestions(state, action: PayloadAction<[Object]>) {
      state.addressSuggestions = action.payload;
    },
    setSentryKey(state, action: PayloadAction<string>) {
      state.sentryKey = action.payload;
    },
    setCustomerDetails(state, action: PayloadAction<StripeIntent>) {
      state.stripeIntent = action.payload;
    },
    setStripeIntentResult(state, action: PayloadAction<any>) {
      state.stripeIntentResult = action.payload;
    },
    setErrorLogResult(state, action: PayloadAction<any>) {
      state.errorLogResult = action.payload;
    },
    getVerificationAddressResult(state, action: PayloadAction<any>) {
      state.verificationAddressResult = action.payload;
    },
    setGTMKey(state, action: PayloadAction<string>) {
      state.gtmId = action.payload;
    },
  },
});

export const {
  setSentryKey,
  setGTMKey,
  getClientCodeStart,
  getClientCodeSuccess,
  getClientCodeFailed,
  getMembershipGroupsStart,
  getMembershipGroupsSuccess,
  getMembershipGroupsFailure,
  getContactDetailsStart,
  getContactDetailsSuccess,
  getContactDetailsFailure,
  setDonationAmtAndFreq,
  setCoverCosts,
  setSelectedPackageId,
  setSelectedMembership,
  setAdditionalInformation,
  setSelectedNewGift,
  getPaymentMethodsStart,
  getPaymentMethodsSuccess,
  getPaymentMethodsFailed,
  setUpdateComplete,
  setCancellationStatus,
  setGiftAidClaimAmount,
  setGiftAidCheckbox,
  setContactSignUp,
  setPaymentNotice,
  setCreditCard,
  setExpiryMM,
  setExpiryYY,
  setAddressSuggestions,
  getVerificationAddressResult,
  setCustomerDetails,
  setStripeIntentResult,
  setErrorLogResult,
} = appSlice.actions;

export default appSlice.reducer;

export const fetchMembershipGroups = (): AppThunk => async (dispatch) => {
  try {
    dispatch(getMembershipGroupsStart());
    const membershipGroups = await getMembershipGroups();
    dispatch(getMembershipGroupsSuccess(membershipGroups));
  } catch (err) {
    dispatch(getMembershipGroupsFailure(err.toString()));
  }
};

export const contactSearch =
  (constituentNumber: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getContactDetailsStart());
      const contactDetails = await search(constituentNumber);
      dispatch(getContactDetailsSuccess(contactDetails));
      dispatch(fetchPaymentSchedule(contactDetails.contactId));
      dispatch(fetchContactPaymentMethods(contactDetails.contactId));
    } catch (err) {
      dispatch(getContactDetailsFailure(err.message));
    }
  };

export const updateContact =
  (contactDetails: ContactDetails): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getContactDetailsStart());
      const newContactDetails = await updateContactDetails(contactDetails);
      dispatch(getContactDetailsSuccess(newContactDetails));
      dispatch(setUpdateComplete(true));
      setTimeout(() => {
        dispatch(setUpdateComplete(false));
      }, 5000);
    } catch (err) {
      dispatch(getContactDetailsFailure(err.message));
    }
  };

export const fetchContactPaymentMethods =
  (constituentId: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getPaymentMethodsStart());
      const paymentMethods = await getPaymentMethods(constituentId);
      dispatch(getPaymentMethodsSuccess(paymentMethods));
    } catch (err) {
      dispatch(getPaymentMethodsFailed());
    }
  };

// Fetching AutoCompleted Address Results
export const fetchVerificationAddressResults =
  (formData: FormData, pageName: string): AppThunk =>
  async (dispatch) => {
    try {
      // Fetch donation page details
      const results = await getAddressAutocompleteResults(formData, pageName);
      dispatch(getVerificationAddressResult(results));
    } catch (err) {
      console.error(err);
    }
  };

export const fetchStripeIntent =
  (formData: FormData): AppThunk =>
  async (dispatch) => {
    try {
      // Fetch donation page details
      const results = await getStripeIntentResult(formData);
      dispatch(setStripeIntentResult(results));
    } catch (err) {
      console.error(err);
      dispatch(
        setStripeIntentResult({
          status: 'failed',
          error: err,
        })
      );
    }
  };
