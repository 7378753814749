// DUTCH (NL)

export default {
  // START Aria translation
  // aria_enter: 'Beginning donation section',
  // aria_flow:
  // 'Starting {{routeName}} section. Section {{sectionIndex}} of {{totalSteps}}',
  input_amount_field_name: 'Invoerbedragveld',
  aria_input_amount_field:
    'Invoerveld: Voer een aangepast donatiebedrag in (tot op de dichtstbijzijnde dollar)',
  aria_amount_summary: 'Bestelbedrag Samenvatting',
  aria_member_summary: 'Overzicht lidmaatschapsbedrag',
  aria_help_icon: 'Informatie/Help-pictogram',
  aria_required: 'Verplicht',
  aria_designation_button: 'aanwijzen aan',
  aria_designation_selection_label: 'Kies een andere benaming',
  aria_year: '2-cijferig vervaljaar',
  aria_month: '2-cijferige vervalmaand',
  aria_security_code:
    'Beveiligingscode creditcard. 3- of 4-cijferige code op de achterkant van uw kaart',
  aria_unexpected_error_link:
    'Klik hier en neem direct contact op met ons donorserviceteam.',
  // END Aria translation
  internatl_error_500:
    '500 - Interne serverfout opgetreden. Neem onmiddellijk contact op met ons team',
  stripe_promise_error: `Kan Stripe Promise niet laden`,
  loading: 'Bezig met laden...',
  loading_image: 'Afbeelding laden ...',
  verification: 'Verificatie bezig...',
  timeout: 'Er is een time-outfout opgetreden. Probeer het later opnieuw.',
  company: 'Bedrijfsnaam',
  company_valication: 'Vul a.u.b. de bedrijfsnaam in',
  company_help_text:
    'De naam van het bedrijf of de organisatie die op de bevestiging en/of kwitantie zal verschijnen.',
  employer: 'Mijn werkgever',
  salutation: 'Begroeting',
  first_name: 'Voornaam',
  first_name_validation: 'Gelieve uw voornaam in te vullen',
  last_name: 'Achternaam',
  last_name_validation: 'Gelieve uw achternaam in te voeren',
  email: 'E-mail',
  email_validation: 'Gelieve een geldig e-mailadres in te geven',
  email_help_text:
    'Uw ontvangstbevestiging en/of belastingbewijs wordt hier per e-mail verzonden.',
  phone: 'Telefoon',
  phone_validation:
    'Voer een geldig telefoonnummer in zonder decimalen of streepjes',
  address: 'Adres',
  address2: 'Adres 2',
  address_validation: 'Voer alstublieft uw adres in',
  suite: 'Suite',
  city: 'stad',
  city_validation: 'Voer alstublieft uw stad in',
  province: 'Provincie',
  province_validation: 'Voer alstublieft uw provincie in',
  postal_code: 'Postcode',
  postal_code_validation: 'Voer een geldige postcode in',
  country: 'Land',
  country_validation: 'Voer een geldig land in',
  amount_raised: 'Opgehaald bedrag',
  goal: 'Doel',
  dedication_checkbox:
    'Ja, mijn donatie is ter ere of ter nagedachtenis van iemand',
  honour_dedication: 'Ter ere van...',
  memory_dedication: 'Ter nagedachtenis van...',
  show_honour_message:
    'U heeft aangegeven dat u deze donatie doet ter ere van iemand',
  show_memory_message:
    'U heeft aangegeven dat u deze schenking doet ter nagedachtenis aan iemand',
  honouree_label: 'Honoree naam',
  honouree_validation: 'Voer een geldige naam in',
  recipient_email: 'Voer het e-mailadres van de ontvanger in',
  special_message: 'Deel hier uw speciale bericht. (Optioneel)',
  acknowledgment_checkbox:
    'Ja, ik wil graag een melding van mijn geschenk sturen',
  recipient_email_validation:
    'Voer het e-mailadres of fysiek adres van de ontvanger in',
  special_message_validation: 'Voer een bericht in voor de ontvanger',
  employer_matches_checkbox: 'Mijn werkgever matcht donaties',
  anonymousHelptext:
    'Dit zal uw naam en donorinformatie verbergen voor alle openbare communicatie; het goede doel zal uw informatie echter nog steeds ontvangen.',
  giftAid_checkbox:
    'Ja, ik wil graag een gift van <1>{{gift}}</1> (25% van de donatie) toevoegen bovenop mijn <2>{{donation}}</2> donatie.',
  month: 'Maand',
  year: 'Jaar',
  privacy_policy: 'Privacybeleid',
  terms_conditions: 'Voorwaarden',
  minimum_amount: 'Minimale hoeveelheid',
  donate_btn: 'Doneer',
  start_p2p_btn: 'Begin met fondsenwerving',
  next_btn: 'De volgende',
  prev_btn: 'Vorige',
  thank_you: 'Bedankt, {{name}}!',
  donation_received: 'Uw donatie is ontvangen.',
  confirmationDetails_thank_you: 'Bedankt, {{name}}',
  confirmationDetails_donation_received:
    'Uw donatie van <1>{{donationAmount}}</1> is ontvangen.',
  confirmationDetails_receipt:
    'Er wordt een belastingbewijs verzonden naar <em>{{email}}</em>.',
  donate_once: 'Doneer\neen keer',
  donate_monthly: 'Doneer\nmaandelijks',
  donate_annually: 'Doneer\njaarlijks',
  finish_btn: 'Af hebben',
  cancel_btn: 'annuleren',
  redirectMessage: 'U wordt doorgestuurd naar:',
  seconds: 'seconden',
  processing: 'Verwerken van uw donatie ...',
  processingEP: 'Verwerking van uw registratie ...',
  processingCD: 'Uw contactgegevens bijwerken ...',
  processingPaymentSchedule: 'Uw betalingsgegevens bijwerken ...',
  card_number: 'Kaartnummer',
  card_validation: 'Voer een geldig creditcardnummer in',
  expiry_date_mm: 'MM',
  expiry_date_yy: 'JJ',
  expiry_date_mmyy: 'MM/JJ',
  expiry_date_mm_validation:
    'Voer een geldige vervaldatum (MM) in. Het eerste cijfer is 0 voor maanden met één cijfer.',
  expiry_date_yy_validation: 'Voer een geldige vervaldatum in (YY).',
  card_security_code: 'CVV',
  card_security_code_validation: 'Voer de CVV in',
  giving: 'Geven <1>{{currency}}</1>',
  share_btn: 'Delen',
  share_on: 'Delen op',
  now_booking: 'Nu boeken',
  book_tickets: 'Boek tickets',
  use_existing_credit_card: 'Bestaande creditcard gebruiken',
  select: 'Selecteer',
  card: 'Kaart',
  next: 'De volgende',
  last: 'Laatste',
  cardType: 'Kaarttype',
  expiry: 'Vervaldatum',
  apple_pay: 'Apple Pay',
  google_pay: 'Google Pay',
  donate: 'Doneer',
  of_goal: 'van het doel',
  supporters: 'Supporters',
  days_left: 'Dagen over',
  minimum_donation_of: 'Minimale donatie van',
  top_fundraising_teams: 'Top fondsenwervingsteams',
  top_individual_fundraisers: 'Beste individuele inzamelingsacties',
  individual_subtitle: 'Start vandaag nog uw individuele inzamelingsactie!',
  team_subtitle: 'Start vandaag nog uw teaminzamelingsactie!',
  campaign_title: 'Laten we gaan!',
  individual_fundraiser_btn: 'Maak een individuele pagina',
  team_fundraiser_btn: 'Maak een teampagina',
  individual_fundraiser_tab: "Individuele pagina's",
  team_fundraiser_tab: "Teampagina's",
  campaign_name: 'Naam fondsenwervingspagina',
  team_campaign_name: 'Naam van teaminzamelingsactie',
  campaign_name_validation: 'Voer een geldige campagnenaam in',
  campaign_goal: 'Fondsenwervingsdoel',
  team_campaign_goal: 'Teaminzamelingsdoel',
  campaign_goal_validation: 'Voer een geldig inzamelingsdoel in',
  campaign_url: 'Campagne-URL',
  start_date: 'Startdatum',
  end_date: 'Einddatum',
  create_campaign_btn: 'Maak een inzamelingspagina',
  update_campaign_btn: 'Update de inzamelingspagina',
  delete_campaign_btn: 'Verwijder deze pagina',
  cancel_changes_btn: 'Stop veranderingen',
  password: 'Wachtwoord',
  password_validation: 'Voer uw wachtwoord in',
  password_requirements:
    'Het wachtwoord moet minimaal 8 tekens, een hoofdletter, een cijfer en een speciaal hoofdlettergebruik bevatten',
  sign_in: 'Log in',
  forgot_password: 'Wachtwoord vergeten?',
  sign_up: 'Aanmelden',
  dont_have: 'Nog geen account?',
  already_have: 'Heb je al een account?',
  go_back: 'Ga terug',
  forgot_password_title: 'Wachtwoord opnieuw instellen',
  forgot_password_btn: 'Stuur reset-instructies',
  download_media_kit: 'Download Mediakit',
  learn_to_fundraise: 'Leer geld inzamelen',
  help_center: 'Helpcentrum',
  days_left_to_donate: 'Dagen over om te doneren',
  days_left_to_fundraise: 'Dagen over om geld in te zamelen',
  quick_links: 'Snelle links',
  edit_campaign_btn: 'Bewerk',
  view_campaign_btn: 'Visie',
  share_my_page_btn: 'Delen',
  team_campaigns: 'Teamcampagnes',
  your_campaigns: "Uw inzamelingspagina's",
  activity: 'Activiteit',
  settings: 'Instellingen',
  birthday: 'Verjaardag (optioneel)',
  save_changes: 'Wijzigingen opslaan',
  edit_profile_title: 'Profiel bewerken / bijwerken',
  profile_photo: 'Profielfoto',
  upload_photo: 'Upload foto',
  manage_prefs_title: 'Beheer voorkeuren',
  donation_updates: 'Donatie-updates en aankomende evenementen',
  phone_campaigns: 'Telefonische campagnes',
  updates_via_mail: 'Updates via e-mail',
  old_password: 'Oud Wachtwoord',
  new_password: 'nieuw paswoord',
  old_password_validation: 'Voer uw oude wachtwoord in',
  new_password_validation: 'Voer uw nieuwe wachtwoord in',
  change_password_title: 'Wachtwoord wijzigen',
  edit_profile: 'Bewerk profiel',
  my_profile: 'Mijn profiel',
  edit_campaign: 'Campagne bewerken',
  log_out: 'Uitloggen',
  start_a_campaign_btn: 'Start een campagne',
  campaign_photo: 'Fondsenwerving achtergrondafbeelding',
  recommended_photo_size: 'Aanbevolen fotoformaat: 1240 x 600 pixels',
  mission_statement: 'Missieverklaring',
  characters_remaining: '{{count}} tekens resterend',
  edit_campaign_title_individual: 'Bewerk uw individuele inzamelingspagina',
  edit_campaign_title_team: 'Bewerk uw teaminzamelingspagina',
  edit_campaign_title_team_member: 'Bewerk uw inzamelingspagina voor teamleden',
  deletion_confirmation_question:
    'Weet je zeker dat je deze inzamelingspagina wilt verwijderen?',
  join_team_btn: 'Kom bij het team',
  join_team_subtitle: 'Doe mee met {{name}}',
  donated_by: 'Gedoneerd door',
  campaign_supporters: 'Aanhangers van de campagne',
  display_name:
    'Voer hier een weergavenaam in - deze verschijnt op de inzamelingspagina',
  leave_message:
    'Deel uw ondersteuningsbericht - dit wordt weergegeven op de pagina van de organisatie of fondsenwervers, zodat ze weten dat ze een geschenk van u hebben ontvangen (optioneel)',
  support_help_text:
    "Berichten zonder naam worden weergegeven als 'Donated by Anonymous'",
  hide_name_checkbox: 'Geef mijn naam niet weer op de campagnepagina',
  show_support_title: 'Deel een ondersteuningsbericht',
  show_support_subtitle:
    'Laat inzamelingsacties weten wat je hebt gegeven en waarom.',
  creating_campaign: 'Uw campagne maken ...',
  failed_campaign_creation: 'Maken van campagne mislukt',
  join_team_checkbox: 'Sta anderen toe om zich bij uw team aan te sluiten',
  credentials_error: 'Ongeldig e-mail adres of wachtwoord',
  email_exists: 'dit email adres is al geregistreerd',
  about_campaign: 'Over de campagne',
  teams: 'Teams',
  team_members: 'Leden van het team',
  participants: 'Deelnemers',
  anonymous: 'Anoniem',
  fundraising_team_for: 'Fondsenwervingsteam voor:',
  fundraising_for: 'Fondsenwerving voor:',
  fundraising_team_member: 'Lid van:',
  no_team_members: 'Niemand heeft zich aangemeld als lid van dit team.',
  fundraising_dates: 'Fondsenwervingsdata',
  return_back_btn: 'Ga terug naar profiel',
  changes_saved_btn: 'Wijzigingen opgeslagen!',
  no_changes_btn: 'Geen wijzigingen om op te slaan',
  password_reset_success:
    'Instructies voor het opnieuw instellen van het wachtwoord zijn verzonden naar {{email}}.',
  total_amount_raised: 'Totaal ingezameld bedrag',
  amount: '<1>{{currencySymbol}}{{amount}}</1>',
  individual_member_of: 'Individueel lid van:',
  team_member_of: 'Teamlid van:',
  role_team_member: '(Rol: teamlid)',
  role_team_captain: '(Rol: teamcaptain)',
  no_individual_pages: "Je hebt geen individuele inzamelingspagina's.",
  no_team_pages: "Je hebt geen inzamelingspagina's voor teams.",
  no_pages_to_display: "Geen inzamelingspagina's om weer te geven.",
  join_as_individual_btn: 'Doe mee als individu',
  create_a_team_btn: 'Creëer een team',
  expired: 'niet meer geldig',
  share_on_facebook: 'Delen op Facebook',
  share_on_twitter: 'Delen op Twitter',
  share_on_linkedin: 'Deel op LinkedIn',
  failure_message: 'Kan betaling niet verwerken',
  search: 'Zoeken',
  constituentNumber: 'Componentnummer',
  contact_not_found: 'Bestanddeel niet gevonden',
  failed_password_change: 'Het oude wachtwoord komt niet overeen',
  success_password_change: 'Wachtwoord succesvol bijgewerkt',
  lifetimeGiving: 'Levenslang',
  givingHistory: 'Geschiedenis geven',
  thisYearGiving: 'Dit jaar',
  lastGiftAmount: 'Laatste geschenkbedrag',
  recurringGift: 'Terugkerende gift',
  creditCard: 'Kredietkaart',
  childRecurringGift: 'Terugkerende geschenkstatus voor kinderen',
  membership: 'Lidmaatschap',
  fetching_pages: "Inzamelingspagina's ophalen ...",
  title_404: '404',
  subtitle_404: 'Sorry, we konden uw pagina niet vinden.',
  message_404: 'Breng me terug naar -',
  home_page: 'Startpagina',
  update_contact_info: 'Update contactgegevens',
  update_payment_info: 'Update betalingsgegevens',
  update_payment_schedule: 'Werk het betalingsschema bij',
  update: 'Bijwerken',
  update_saved_message: 'Update succesvol opgeslagen',
  new_gift_btn: 'Nieuw cadeau',
  new_gift_title: 'Een nieuw cadeau creëren',
  create_new_contact_btn: 'Creëer een cadeau voor een nieuwe donateur',
  cancel_donation: 'Donatie annuleren',
  back: 'Terug',
  start_by: 'Begin met het invoeren van een',
  or: 'Of',
  FeltPressure: 'Donor voelde druk van fondsenwerving',
  NoLongerAfford: 'Kan het zich niet langer veroorloven',
  UnderMinimumWage: 'Onder de minimumleeftijd',
  SpouseObjected: 'Echtgenoot maakte bezwaar',
  ChangedMind: 'Van gedachte veranderd',
  IntendedShortTerm: 'Donor bedoeld voor een korte termijn schenking',
  IntendedOneTime: 'Donor was van plan een eenmalige donatie te doen',
  Deceased: 'Overleden',
  Misunderstood: 'Onbegrepen toewijding',
  OtherCharities: 'Doneren aan andere goede doelen',
  PaymentDeclined: 'Betaling geweigerd',
  Complaint: 'Klacht',
  AccountClosed: 'Account gesloten',
  AlreadyDonating: 'Al aan het doneren',
  NoReason: 'Geen reden gegeven',
  continue_session: 'Houd me aangemeld',
  session_timeout: 'Time-out sessie',
  session_message:
    'De huidige sessie verloopt over <1>{{countdown}}</1> seconden.',
  continue_confirmation: 'wil je verder gaan?',
  master_donate_btn: 'Doneer aan deze campagne',
  team_donate_btn: 'Doneer aan het team',
  team_member_donate_btn: 'Doneer aan de inzamelingsactie van {{name}}',
  note: 'Opmerking:',
  bg_image_note:
    "Deze afbeelding verschijnt als achtergrond op de inzamelingspagina's. Het wordt gedeeltelijk gedekt door de Fundraising Stats Component (Box) op schermen van desktopformaat en ongeveer 90% bedekt op kleinere schermformaten.",
  sample_campaign_stats:
    'Dit is een voorbeeld van het onderdeel Fondsenwervingsstatistieken om te demonstreren hoe de achtergrondafbeelding op een inzamelingspagina wordt weergegeven.',
  preview: 'Voorbeeld',
  donation_through: 'De schenking is gedaan',
  donation: 'Bijdrage',
  view_fundraising_page: 'Bekijk de inzamelingspagina',
  how_often_donate: 'Hoe vaak wil je doneren?',
  select_amount_donate: 'Selecteer het bedrag dat u wilt schenken:',
  direct_your_gift: 'Hoe wil je je cadeau richten?',
  once: 'Een keer',
  monthly: 'Maandelijks',
  annually: 'jaarlijks',
  why_donate_monthly: 'Welke impact heeft een maandelijkse donatie?',
  eft: 'EFT',
  bank: 'Bank',
  bank_check: 'Bankcheque',
  bank_name: 'Banknaam',
  acc_no: 'Rekeningnummer',
  routing_no: 'Doorvoernummer en nummer van financiële instelling (8 cijfers)',
  chequing: 'chequing',
  savings: 'besparingen',
  select_acc_type: 'Selecteer Accounttype',
  acc_no_validation: 'Rekeningnummer Is benodigd',
  routing_no_validation:
    'Doorvoernummer en nummer van financiële instelling Is benodigd',
  bank_name_validation: 'Banknaam is vereist',
  Completed: 'Voltooid',
  Terminated: 'Geannuleerd',
  Failed: 'Mislukt',
  TransactionRefund: 'Terugbetaling',
  InProgress: 'Bezig',
  notPaymentScheduleMessage: 'Geen betalingsschema beschikbaar',
  notPaymentMethodsMessage: 'Geen betalingsmethode beschikbaar',
  Amount: 'Bedrag',
  Name: 'Naam',
  Team: 'Team',
  gift_aid: 'Geschenkhulp',
  donor_details: 'Donorgegevens',
  payment_details: 'Betalingsdetails',
  Total: 'Totaal',
  other_btn: 'Andere',
  anonymous_checkbox: 'Maak mijn donatie anoniem',
  your_donation: 'Uw donatie <1>{{currency}}</1>',
  your_monthly_donation: 'Uw maandelijkse donatie <1>{{currency}}</1>',
  your_annually_donation: 'Uw jaarlijkse donatie <1>{{currency}}</1>',
  your_membership: 'Uw lidmaatschap <1>{{currency}}</1>',
  include_membership_checkbox:
    'Betrek mijn lidmaatschap bij mijn <1>{{donation}}</1> <2>{{donationFrequency}}</2> donatie.',
  share_generosity: 'Deel uw vrijgevigheid en inspireer anderen:',
  help_further: 'Wil je verder helpen?',
  get_involved_title: 'Raak betrokken',
  set_up_fundraising_btn: 'Zet mijn inzamelingsactie op',
  start_fundraising_accordian_title: 'Steun een campagne',
  donateMonthlyLink: 'Ik zou graag maandelijks willen doneren',
  donateAnnuallyLink: 'Ik zou graag jaarlijks willen doneren',
  enter_amount: 'Voer een bedrag in',
  selection_label: 'Anders',
  order_label: 'Selecteer alstublieft',
  membership_order_label: 'Selecteer (optioneel)',
  support_display_all: 'Geef mijn naam, bericht en donatiebedrag weer.',
  support_hide_amount:
    'Geef alleen mijn naam en bericht weer (verbergt het donatiebedrag).',
  support_hide_name:
    'Geef alleen mijn donatiebedrag en bericht weer (verbergt naam).',
  support_hide_all:
    'Nee, verberg alstublieft mijn naam, bericht en donatiebedrag.',
  greatest_need: 'Grootste behoefte',
  background_image_alt_text: 'dynamische achtergrondthema-afbeelding',
  summary_link_description: `Klik op 'Uw donatie' om het bedrag van uw gift te wijzigen`,
  unexpected_error_message:
    'Er is een fout opgetreden. Maar uw transactie is verwerkt en zal op uw afschrift verschijnen. <1>Probeer het niet opnieuw.</1><br /><br /><2>Klik hier</2> of neem onmiddellijk contact op met ons donorserviceteam om te laten weten dat u dit bericht heeft ontvangen.',
  applePay_error: `Apple Pay is alleen beschikbaar indien ingeschakeld met iOS-compatibele apparaten (iPhone/iPad/Mac).`,
  googlePay_error:
    'GooglePay is niet ingeschakeld en/of beschikbaar op dit apparaat.',
  additionalInfo: 'Extra informatie',
  additional_info_msg:
    'Als u opmerkingen heeft over uw donatie, kunt u deze hier vermelden.',
  notes_or_direction: 'Opmerkingen of richting:',
  readMore: 'Lees verder',
  showLess: 'Laat minder zien',
  isCompanyGift: 'Is dit een bedrijfsdonatie',
  yes: 'Ja',
  no: 'Nee',
  tribute_type: 'Wil je dat we een kaartje sturen?',
  no_sendTribute: 'Nee,\ndank u',
  email_sendTribute: 'Ja,\nper e-mail',
  mail_sendTribute: 'Ja,\nper post',
  addressee_name: 'Verstuur kaart naar',
  addressee_name_validation: `Voer de naam van de ontvanger in`,
  required_pwd: 'Voer uw wachtwoord in',
  pwd_match:
    'Moet 8 tekens bevatten, één hoofdletter, één kleine letter, één cijfer en één speciaal teken',
  MissionCRM_label: 'Mission CRM-label',
  recaptcha_token_error:
    'Het ophalen van reCAPTCHA-token is momenteel niet gelukt. Probeer het later opnieuw.',
  recaptcha_failure:
    'Slecht verzoek, slecht recaptcha-token. Controleer uw gegevens nogmaals en probeer het opnieuw.',
};
