import * as React from 'react';
import { useTheme } from 'react-jss';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { DonationPageType, PageBackgroundType, PageKind } from '@root/enums';
import { PagePlacement } from '@root/enums/PagePlacement';
import { DonationPageTheme } from '@root/interfaces';
import { DonationPageDetails } from '@modules/donation/types';
import { useResizeDetector } from 'react-resize-detector/build/withPolyfill';
import { isDesktop } from 'react-device-detect';
import { Container, Row, Col, Carousel, Image } from 'react-bootstrap';
import { useMediaQuery } from 'react-responsive';
import ProgressIndicator from '@components/atoms/progressIndicator';
import { RootState } from '@app/RootState';
import { getOrderTotal } from '@utils/index';
import { HeaderSkeleton } from '@components/molecules/header/header.skeleton';
import DonationRouter from '@modules/donation/router';
import Footer from '@components/atoms/footer';
import globalStyles from '@styles/global';
import label from '@root/assets/MissionCRM_Label.svg';
import useStyles from './donationLayout.style';

const { lazy, Suspense } = React;
const Header = lazy(() => import('@components/molecules/header'));
const OrderSummary = lazy(() => import('@components/atoms/orderSummary'));

export const BASE_PATH = '/Donation/:friendlyUrl';

interface DonationLayoutScreenProps {
  pageDetails?: DonationPageDetails;
  donationPageTypeId?: DonationPageType;
  friendlyUrl?: any;
}

const DonationLayout: React.FC<DonationLayoutScreenProps> = ({
  pageDetails = null,
  donationPageTypeId,
  friendlyUrl,
}) => {
  globalStyles();
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const theme: DonationPageTheme = useTheme();

  // useMediaQuery - based on window.innerwidth
  const minWith1200 = useMediaQuery({
    query: '(min-width: 1200px)',
  });

  const {
    contactDetails,
    selectedNewGift,
    donationAmtAndFreq,
    coverCosts,
    selectedMembership,
  } = useSelector((state: RootState) => state.app);

  const { progressIndicator } = useSelector(
    (state: RootState) => state.donation
  );

  const [defaultRouteName, setDefaultRouteName] = React.useState('donate');
  const [route, setRoute] = React.useState(defaultRouteName);
  const [usePageImages, setUsePageImages] = React.useState(false);
  const [pageImages, setPageImages] = React.useState({});
  const [pageTitle, setPageTitle] = React.useState(null);
  const [showGivingComponent, setShowGivingComponent] = React.useState(false);
  const [membershipAmount, setMembershipAmount] = React.useState(null);
  const [orderTotal, setOrderTotal] = React.useState(null);
  const [updatedDonationAmount, setUpdatedDonationAmount] =
    React.useState(null);
  const [headSectionHeight, setHeadSectionHeight] = React.useState(127);
  const [contentAreaHeight, setContentAreaHeight] = React.useState(
    window.innerHeight - 184
  );
  const [imageContainMinWidth] = React.useState(window.innerWidth - 682);
  const rightPagePlacement =
    pageDetails &&
    pageDetails.pagePlacement &&
    pageDetails.pagePlacement === PagePlacement.Right;

  const targetOrigin = '*';

  // iframe useStates
  const [iframe] = React.useState(window.location !== window.parent.location);
  const [imageLoaded, setImageLoaded] = React.useState(false);
  const [rootElement, setRootElement] = React.useState(null);
  const [rootElementHeight, setRootElementHeight] = React.useState(800);
  const [onLoadHeight, setOnLoadHeight] = React.useState(null);
  const [flowRoute, setFlowRoute] = React.useState('donate');

  // Element Refs
  const headerRef = React.useRef(null);

  // ResizeDetector for iframe
  const onResize = React.useCallback(() => {
    if (
      !isDesktop &&
      iframe &&
      rootElement &&
      Math.trunc(onLoadHeight) !== Math.trunc(height)
    ) {
      window.parent.postMessage(null, targetOrigin);
      window.parent.postMessage(rootElement.offsetHeight, targetOrigin);
    }
  }, [iframe, rootElement]);

  const { height, ref } = useResizeDetector({
    refreshMode: 'debounce',
    refreshRate: iframe ? 100 : 1000,
    onResize,
  });

  const showHeader = pageDetails && pageDetails.showHeader;

  const classes = useStyles({});

  React.useEffect(() => {
    setDefaultRouteName(
      donationPageTypeId === DonationPageType.DP ? 'donate' : 'contact'
    );
  }, [donationPageTypeId]);

  React.useEffect(() => {
    window.scrollTo(10, 0);
    const splitPath = pathname.split('/');
    setRoute(splitPath[splitPath.length - 1] || defaultRouteName);
  }, [pathname]);

  React.useEffect(() => {
    if (theme.backgrounds) {
      setUsePageImages(
        !Object.keys(theme.backgrounds).every(
          (x) => theme.backgrounds[x].type === PageBackgroundType.Static
        )
      );
      setPageImages(theme.backgrounds);
    }
  }, [theme]);

  React.useEffect(() => {
    if (donationAmtAndFreq) {
      let amount = donationAmtAndFreq.donationAmount;
      if (selectedMembership && selectedMembership.includeMembership) {
        let membershipAmount =
          selectedMembership.membershipBaseAmount +
          selectedMembership.membershipAmountTax;
        if (
          donationAmtAndFreq &&
          donationAmtAndFreq.givingFrequency !== 'once'
        ) {
          membershipAmount /= 12;
        }
        amount -= membershipAmount;
      }
      setUpdatedDonationAmount(amount);
    }
  }, [selectedMembership, donationAmtAndFreq]);

  React.useEffect(() => {
    const total = getOrderTotal({
      coverCosts,
      pageDetails,
      donationAmount: updatedDonationAmount,
      selectedMembership,
      givingFrequency: donationAmtAndFreq && donationAmtAndFreq.givingFrequency,
      pageKind: PageKind.Donation,
    });
    setOrderTotal(total);
  }, [
    donationAmtAndFreq,
    updatedDonationAmount,
    coverCosts,
    selectedMembership,
  ]);

  React.useEffect(() => {
    let amount = null;
    if (selectedMembership && selectedMembership.membership) {
      amount +=
        donationAmtAndFreq && donationAmtAndFreq.givingFrequency !== 'once'
          ? selectedMembership.membershipBaseAmount / 12 +
            selectedMembership.membershipAmountTax / 12
          : selectedMembership.membershipAmount;
    }
    setMembershipAmount(amount);
  }, [selectedMembership, donationAmtAndFreq]);

  React.useEffect(() => {
    switch (route) {
      case 'contact':
        setPageTitle(t('donor_details'));
        setShowGivingComponent(true);
        break;
      case 'payment':
        setPageTitle(t('payment_details'));
        setShowGivingComponent(true);
        break;
      case 'giftAid':
        setPageTitle(t('gift_aid'));
        setShowGivingComponent(true);
        break;
      case 'membership':
        setPageTitle(t('membership'));
        setShowGivingComponent(true);
        break;
      case 'additionalInfo':
        setPageTitle(t('additionalInfo'));
        setShowGivingComponent(true);
        break;

      default:
        setPageTitle(null);
        setShowGivingComponent(false);
        break;
    }
  }, [route]);

  const handleHeaderLoad = (imgIsTrusted) => {
    const windowHeight = window.innerHeight;
    if (imgIsTrusted && showHeader) {
      setImageLoaded(true);
      const headerHeight = headerRef.current.clientHeight;
      setHeadSectionHeight(headerHeight);
      if (route === defaultRouteName) {
        setContentAreaHeight(windowHeight - headerHeight - 84);
      }
    }
  };

  // Sending height to parent window for iframe
  React.useEffect(() => {
    if (iframe) {
      setRootElement(document.querySelector<HTMLInputElement>('#root'));
      if (rootElement) {
        if (!onLoadHeight && height) {
          setOnLoadHeight(Math.trunc(height));
        }

        if (isDesktop && onLoadHeight && height && headSectionHeight) {
          window.parent.postMessage(Math.trunc(height) + 84, targetOrigin);
        }

        if (!isDesktop) {
          window.parent.postMessage(rootElement.offsetHeight, targetOrigin);
          setTimeout(() => {
            setRootElementHeight(rootElement.offsetHeight);
          }, 500);
        }
      }
      if (route !== flowRoute) {
        window.parent.postMessage(null, targetOrigin);
        setOnLoadHeight(null);
        setFlowRoute(route);
      }
    }
  }, [
    rootElement,
    rootElementHeight,
    imageLoaded,
    onLoadHeight,
    height,
    route,
    flowRoute,
  ]);

  return (
    <Suspense fallback={<div>{t('loading')}</div>}>
      {/* {!isSm && ( */}
      <div className={classes.donationLayoutContainer}>
        <div
          className={`${classes.themeColor} ${
            rightPagePlacement
              ? classes.themeColorRight
              : classes.themeColorLeft
          }`}
        />
        {pageImages && usePageImages && (
          <div
            className={`${classes.imageContainer} ${
              rightPagePlacement
                ? classes.imageContainerRight
                : classes.imageContainerLeft
            }`}
            style={{
              minWidth: !iframe && minWith1200 ? imageContainMinWidth : 'auto',
            }}
          >
            <Carousel
              fade
              controls={false}
              indicators={false}
              interval={null}
              activeIndex={Object.keys(theme.backgrounds).findIndex(
                (x) => x === progressIndicator
              )}
            >
              {Object.keys(pageImages).map((image, index) => {
                return pageImages[image].type === PageBackgroundType.URL ? (
                  <Carousel.Item key={index} className={classes.caruselItem}>
                    <img
                      className={classes.caruselImage}
                      src={pageImages[image].value}
                      alt={t('background_image_alt_text')}
                      style={{
                        minWidth:
                          !iframe && minWith1200
                            ? imageContainMinWidth
                            : 'auto',
                      }}
                    />
                  </Carousel.Item>
                ) : (
                  <Carousel.Item key={index} className={classes.caruselItem}>
                    <div
                      style={{
                        backgroundColor: pageImages[image].value,
                        width: '100%',
                        height: '100%',
                      }}
                    />
                  </Carousel.Item>
                );
              })}
            </Carousel>
          </div>
        )}

        <div
          className={`${classes.opacity} ${
            rightPagePlacement ? classes.opacityRight : classes.opacityLeft
          }`}
        />
      </div>
      {/* )} */}

      <Container
        fluid
        className={`${classes.contentContainer} ${
          rightPagePlacement
            ? classes.contentContainerRight
            : classes.contentContainerLeft
        }`}
      >
        <Row className={classes.contentRow}>
          <Col
            as="article"
            className={classes.contentCol}
            id="pageContent"
            ref={ref}
          >
            <Row as="section" ref={headerRef}>
              <Col className={classes.headerSection}>
                <Row
                  className={
                    showHeader ? classes.headerContainer : classes.hideHeader
                  }
                >
                  {showHeader && (
                    <Col>
                      <Suspense fallback={<HeaderSkeleton />}>
                        <Header
                          page={pageDetails}
                          donationPageTypeId={donationPageTypeId}
                          color="transparent"
                          showLogoOnly={showHeader}
                          loadImg={handleHeaderLoad}
                        />
                      </Suspense>
                    </Col>
                  )}
                </Row>
                <Row className={classes.progressIndicatorContainer}>
                  <Col
                    xs={
                      showHeader
                        ? { span: 9, offset: 3 }
                        : { span: 12, offset: 0 }
                    }
                    className={
                      showHeader && classes.progressIndicatorContainerCol
                    }
                  >
                    <ProgressIndicator page={pageDetails} />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row
              as="section"
              aria-label={route && route === 'giftAid' ? 'thank you' : null}
              className={classes.contentSubRow}
              style={{
                minHeight:
                  !iframe || isDesktop ? contentAreaHeight - 1 : 'auto',
              }}
            >
              <Col className={classes.contentSubCol}>
                {showGivingComponent && (
                  <Suspense fallback={<div>{t('loading')}</div>}>
                    <OrderSummary
                      totalAmount={orderTotal}
                      membershipAmount={membershipAmount}
                      pageTitle={pageTitle}
                      donationPageTypeId={donationPageTypeId}
                      frequency={
                        donationAmtAndFreq && donationAmtAndFreq.givingFrequency
                      }
                    />
                  </Suspense>
                )}
                <DonationRouter
                  donationPageDetails={pageDetails}
                  donationPageTypeId={donationPageTypeId}
                  friendlyUrl={friendlyUrl}
                  hasContactDetails={!!contactDetails}
                  selectedNewGift={selectedNewGift}
                  orderTotal={orderTotal}
                />
                <Footer page={pageDetails} route={route} />
              </Col>
            </Row>
            <Image
              className={`${classes.label} ${
                rightPagePlacement ? classes.labelRight : classes.labelLeft
              }`}
              src={label}
              alt={t('MissionCRM_label')}
            />
          </Col>
        </Row>
      </Container>
    </Suspense>
  );
};

export default React.memo(DonationLayout);
