// SPANISH (ES)

export default {
  // START Aria translation
  // aria_enter: 'Beginning donation section',
  // aria_flow:
  // 'Starting {{routeName}} section. Section {{sectionIndex}} of {{totalSteps}}',
  input_amount_field_name: 'Campo de cantidad de entrada',
  aria_input_amount_field:
    'Campo de entrada: Ingrese el monto de la donación personalizada (al dólar más cercano)',
  aria_amount_summary: 'Importe del pedido Resumen',
  aria_member_summary: 'Resumen del monto de la membresía',
  aria_help_icon: 'Icono de información/ayuda',
  aria_required: 'Requerida',
  aria_designation_button: 'designar a',
  aria_designation_selection_label: 'Seleccione otra designación',
  aria_year: 'Año de caducidad de 2 dígitos',
  aria_month: 'Mes de vencimiento de 2 dígitos',
  aria_security_code:
    'Código de seguridad de tarjeta de crédito. Código de 3 o 4 dígitos que se encuentra en el reverso de su tarjeta',
  aria_unexpected_error_link:
    'Haga clic aquí y comuníquese con nuestro equipo de servicios para donantes de inmediato.',
  // END Aria translation
  internatl_error_500:
    '500 - Ocurrió un error interno del servidor. Póngase en contacto con nuestro equipo inmediatamente',
  stripe_promise_error: `No se pudo cargar la promesa de Stripe`,
  loading: 'Cargando...',
  loading_image: 'Cargando imagen ...',
  verification: 'Verificación en curso...',
  timeout:
    'Se ha producido un error de tiempo de espera. Por favor, inténtelo de nuevo más tarde.',
  company: 'nombre de empresa',
  company_valication: 'Por favor, introduzca el nombre de la empresa',
  company_help_text:
    'El nombre de la empresa u organización que aparecerá en el acuse de recibo y / o recibo.',
  employer: 'Mi empleadora',
  salutation: 'Saludo',
  first_name: 'Primer nombre',
  first_name_validation: 'Por favor, introduzca su nombre de pila',
  last_name: 'Apellido',
  last_name_validation: 'Por favor ingrese su apellido',
  email: 'Correo electrónico',
  email_validation:
    'Por favor, introduce una dirección de correo electrónico válida',
  email_help_text:
    'Su acuse de recibo y / o recibo de impuestos se enviarán por correo electrónico aquí.',
  phone: 'Teléfono',
  phone_validation:
    'Ingrese un número de teléfono válido sin decimales ni guiones',
  address: 'Habla a',
  address2: 'Dirección 2',
  address_validation: 'Por favor ingrese su direccion',
  suite: 'Suite',
  city: 'Ciudad',
  city_validation: 'Por favor ingrese su ciudad',
  province: 'Provincia',
  province_validation: 'Por favor ingrese su provincia',
  postal_code: 'Código postal',
  postal_code_validation: 'Ingrese un código postal válido',
  country: 'País',
  country_validation: 'Ingrese un país válido',
  amount_raised: 'Cantidad recaudada',
  goal: 'Objetivo',
  dedication_checkbox: 'Sí, mi donación es en honor o en memoria de alguien',
  honour_dedication: 'En honor de...',
  memory_dedication: 'En memoria de...',
  show_honour_message:
    'Has indicado que estás haciendo esta donación en honor a alguien.',
  show_memory_message:
    'Has indicado que estás haciendo esta donación en memoria de alguien.',
  honouree_label: 'Nombre del homenajeado',
  honouree_validation: 'Ingrese un nombre de homenajeado válido',
  recipient_email:
    'Por favor, introduzca la dirección de correo electrónico del destinatario',
  special_message: 'Comparta su mensaje especial aquí. (Opcional)',
  acknowledgment_checkbox:
    'Sí, me gustaría enviar una notificación de mi regalo',
  recipient_email_validation:
    'Ingrese el correo electrónico o la dirección física del destinatario',
  special_message_validation: 'Ingrese un mensaje para el destinatario',
  employer_matches_checkbox: 'Mi empleador igualará las donaciones',
  anonymousHelptext:
    'Esto ocultará su nombre y la información del donante de todas las comunicaciones públicas; sin embargo, la organización benéfica seguirá recibiendo su información.',
  giftAid_checkbox:
    'Sí, me gustaría agregar una ayuda de regalo de <1>{{gift}}</1> (25% de la donación) además de mi donación de <2>{{donation}}</2>.',
  month: 'Mes',
  year: 'Año',
  privacy_policy: 'Política de privacidad',
  terms_conditions: 'Términos y condiciones',
  minimum_amount: 'Monto minimo',
  donate_btn: 'Donar',
  start_p2p_btn: 'Iniciar la recaudación de fondos',
  next_btn: 'próximo',
  prev_btn: 'Anterior',
  thank_you: '¡Gracias, {{name}}!',
  donation_received: 'Se ha recibido tu donación.',
  confirmationDetails_thank_you: 'Gracias, {{name}}',
  confirmationDetails_donation_received:
    'Se ha recibido su donación de <1>{{donationAmount}}</1>.',
  confirmationDetails_receipt:
    'Se enviará un recibo de impuestos a <em>{{email}}</em>.',
  donate_once: 'Donar\nuna vez',
  donate_monthly: 'Donar\nmensualmente',
  donate_annually: 'Donar\nanualmente',
  finish_btn: 'Terminar',
  cancel_btn: 'Cancelar',
  redirectMessage: 'Serás redirigido a:',
  seconds: 'segundos',
  processing: 'Procesando su donación ...',
  processingEP: 'Procesando su registro ...',
  processingCD: 'Actualización de su información de contacto ...',
  processingPaymentSchedule: 'Actualización de su información de pago ...',
  card_number: 'Número de tarjeta',
  card_validation:
    'Por favor, introduzca un número de tarjeta de crédito válida',
  expiry_date_mm: 'MM',
  expiry_date_yy: 'YY',
  expiry_date_mmyy: 'MM/YY',
  expiry_date_mm_validation:
    'Ingrese una fecha de vencimiento válida (MM). El primer dígito es 0 para meses de un solo dígito.',
  expiry_date_yy_validation: 'Ingrese una fecha de vencimiento válida (YY).',
  card_security_code: 'CVV',
  card_security_code_validation: 'Por favor ingrese el CVV',
  giving: 'Dando <1>{{currency}}</1>',
  share_btn: 'Cuota',
  share_on: 'Compartir en',
  now_booking: 'Ahora reservando',
  book_tickets: 'Reservar entradas',
  use_existing_credit_card: 'Usar tarjeta de crédito existente',
  select: 'Seleccione',
  card: 'Tarjeta',
  next: 'Próximo',
  last: 'Último',
  cardType: 'Tipo de tarjeta',
  expiry: 'Expiración',
  apple_pay: 'Pago de Apple',
  google_pay: 'Pago de Google',
  donate: 'Donar',
  of_goal: 'de objetivo',
  supporters: 'Partidarios',
  days_left: 'Días restantes',
  minimum_donation_of: 'Donación mínima de',
  top_fundraising_teams: 'Mejores equipos de recaudación de fondos',
  top_individual_fundraisers:
    'Principales recaudaciones de fondos individuales',
  individual_subtitle:
    '¡Empiece hoy mismo su recaudación de fondos individual!',
  team_subtitle: '¡Empiece hoy mismo la recaudación de fondos de su equipo!',
  campaign_title: '¡Vamos!',
  individual_fundraiser_btn: 'Crear página individual',
  team_fundraiser_btn: 'Crear página de equipo',
  individual_fundraiser_tab: 'Páginas individuales',
  team_fundraiser_tab: 'Páginas de equipo',
  campaign_name: 'Nombre de la página de recaudación de fondos',
  team_campaign_name: 'Nombre del equipo de recaudación de fondos',
  campaign_name_validation:
    'Ingrese un nombre de campaña de recaudación de fondos válido',
  campaign_goal: 'Objetivo de recaudación de fondos',
  team_campaign_goal: 'Objetivo de recaudación de fondos del equipo',
  campaign_goal_validation:
    'Ingrese un objetivo de recaudación de fondos válido',
  campaign_url: 'URL de campaña',
  start_date: 'Fecha de inicio',
  end_date: 'Fecha final',
  create_campaign_btn: 'Crear página de recaudación de fondos',
  update_campaign_btn: 'Actualizar la página de recaudación de fondos',
  delete_campaign_btn: 'Borra esta página',
  cancel_changes_btn: 'Cancelar cambios',
  password: 'Contraseña',
  password_validation: 'Por favor, introduzca su contraseña',
  password_requirements:
    'La contraseña debe contener al menos 8 caracteres, una mayúscula, un número y un carácter en caso especial',
  sign_in: 'Iniciar sesión',
  forgot_password: '¿Se te olvidó tu contraseña?',
  sign_up: 'Inscribirse',
  dont_have: '¿No tienes una cuenta?',
  already_have: '¿Ya tienes una cuenta?',
  go_back: 'Regresa',
  forgot_password_title: 'Restablecer la contraseña',
  forgot_password_btn: 'Enviar instrucciones de reinicio',
  download_media_kit: 'Descargar el kit de medios',
  learn_to_fundraise: 'Aprenda a recaudar fondos',
  help_center: 'Centro de ayuda',
  days_left_to_donate: 'Días que quedan para donar',
  days_left_to_fundraise: 'Días que quedan para recaudar fondos',
  quick_links: 'enlaces rápidos',
  edit_campaign_btn: 'Editar',
  view_campaign_btn: 'Vista',
  share_my_page_btn: 'Cuota',
  team_campaigns: 'Campañas de equipo',
  your_campaigns: 'Tus páginas de recaudación de fondos',
  activity: 'Actividad',
  settings: 'Ajustes',
  birthday: 'Cumpleaños (opcional)',
  save_changes: 'Guardar cambios',
  edit_profile_title: 'Editar / actualizar perfil',
  profile_photo: 'Foto de perfil',
  upload_photo: 'Subir foto',
  manage_prefs_title: 'Administrar preferencias',
  donation_updates: 'Actualizaciones de donaciones y próximos eventos',
  phone_campaigns: 'Campañas telefónicas',
  updates_via_mail: 'Actualizaciones por correo',
  old_password: 'Contraseña anterior',
  new_password: 'Nueva contraseña',
  old_password_validation: 'Ingrese su contraseña anterior',
  new_password_validation: 'Por favor ingrese su nueva contraseña',
  change_password_title: 'Cambiar la contraseña',
  edit_profile: 'Editar perfil',
  my_profile: 'Mi perfil',
  edit_campaign: 'Editar campaña',
  log_out: 'Cerrar sesión',
  start_a_campaign_btn: 'Iniciar una campaña',
  campaign_photo: 'Imagen de fondo de recaudación de fondos',
  recommended_photo_size: 'Tamaño de foto recomendado: 1240 x 600 píxeles',
  mission_statement: 'Estado de la misión',
  characters_remaining: 'Quedan {{count}} caracteres',
  edit_campaign_title_individual:
    'Edite su página de recaudación de fondos individual',
  edit_campaign_title_team:
    'Edite la página de recaudación de fondos de su equipo',
  edit_campaign_title_team_member:
    'Edite la página de recaudación de fondos de su miembro del equipo',
  deletion_confirmation_question:
    '¿Está seguro de que desea eliminar esta página de recaudación de fondos?',
  join_team_btn: 'Únete al equipo',
  join_team_subtitle: 'Únase a {{name}}',
  donated_by: 'Donado por',
  campaign_supporters: 'Partidarios de la campaña',
  display_name:
    'Ingrese un nombre para mostrar aquí; aparecerá en la página de recaudación de fondos',
  leave_message:
    'Comparta su mensaje de apoyo: aparecerá en la página de la organización o de la recaudación de fondos para que sepan que recibieron un regalo de usted (opcional).',
  support_help_text:
    "Los mensajes sin nombre se mostrarán como 'Donados por anónimos'",
  hide_name_checkbox: 'No mostrar mi nombre en la página de la campaña',
  show_support_title: 'Comparte un mensaje de apoyo',
  show_support_subtitle:
    'Informe a los recaudadores de fondos lo que dio y por qué.',
  creating_campaign: 'Creando su Campaña ...',
  failed_campaign_creation: 'No se pudo crear la campaña',
  join_team_checkbox: 'Permita que otros se unan a su equipo',
  credentials_error: 'Dirección de correo electrónico o contraseña no válidos',
  email_exists: 'Esta dirección de e-mail ya ha sido registrada',
  about_campaign: 'Sobre la Campaña',
  teams: 'Equipos',
  team_members: 'Miembros del equipo',
  participants: 'Participantes',
  anonymous: 'Anónimo',
  fundraising_team_for: 'Equipo de recaudación de fondos para:',
  fundraising_for: 'Recaudación de fondos para:',
  fundraising_team_member: 'Miembro de:',
  no_team_members: 'Nadie se ha registrado como miembro de este equipo.',
  fundraising_dates: 'Fechas de recaudación de fondos',
  return_back_btn: 'Regresar al perfil',
  changes_saved_btn: '¡Cambios guardados!',
  no_changes_btn: 'Sin cambios para guardar',
  password_reset_success:
    'Se han enviado instrucciones para restablecer la contraseña a {{email}}.',
  total_amount_raised: 'Monto total recaudado',
  amount: '<1>{{currencySymbol}}{{amount}}</1>',
  individual_member_of: 'Miembro individual de:',
  team_member_of: 'Miembro del equipo de:',
  role_team_member: '(Rol: miembro del equipo)',
  role_team_captain: '(Rol: Capitán del equipo)',
  no_individual_pages:
    'No tiene páginas de recaudación de fondos individuales.',
  no_team_pages:
    'No tiene ninguna página de recaudación de fondos para el equipo.',
  no_pages_to_display: 'No hay páginas de recaudación de fondos para mostrar.',
  join_as_individual_btn: 'Únase como individuo',
  create_a_team_btn: 'Crear un equipo',
  expired: 'Caducado',
  share_on_facebook: 'Compartir en Facebook',
  share_on_twitter: 'Compartir en Twitter',
  share_on_linkedin: 'Compartir en LinkedIn',
  failure_message: 'No se pudo procesar el pago',
  search: 'Buscar',
  constituentNumber: 'Número de constituyente',
  contact_not_found: 'Constituyente no encontrado',
  failed_password_change: 'La contraseña antigua no coincide',
  success_password_change: 'Contraseña actualizada exitosamente',
  lifetimeGiving: 'Toda la vida',
  givingHistory: 'Dando Historia',
  thisYearGiving: 'Este año',
  lastGiftAmount: 'Monto del último regalo',
  recurringGift: 'Regalo recurrente',
  creditCard: 'Tarjeta de crédito',
  childRecurringGift: 'Estado de regalo recurrente para niños',
  membership: 'Afiliación',
  fetching_pages: 'Obteniendo páginas de recaudación de fondos ...',
  title_404: '404',
  subtitle_404: 'Lo sentimos, no pudimos encontrar tu página.',
  message_404: 'Llévame de vuelta a ...',
  home_page: 'Página de inicio',
  update_contact_info: 'Actualizar la información de contacto',
  update_payment_info: 'Actualizar la información de pago',
  update_payment_schedule: 'Actualizar calendario de pagos',
  update: 'Actualizar',
  update_saved_message: 'Actualización guardada con éxito',
  new_gift_btn: 'Nuevo regalo',
  new_gift_title: 'Creando un nuevo regalo',
  create_new_contact_btn: 'Crear un regalo para un nuevo donante',
  cancel_donation: 'Cancelar donación',
  back: 'atrás',
  start_by: 'Comience ingresando un',
  or: 'O',
  FeltPressure: 'El donante sintió la presión de la recaudación de fondos',
  NoLongerAfford: 'Ya no puedo pagar',
  UnderMinimumWage: 'Por debajo de la edad mínima',
  SpouseObjected: 'Cónyuge objetado',
  ChangedMind: 'Mente cambiada',
  IntendedShortTerm: 'Donante destinado a una donación a corto plazo',
  IntendedOneTime: 'El donante pretendía hacer una donación única',
  Deceased: 'Fallecido',
  Misunderstood: 'Compromiso incomprendido',
  OtherCharities: 'Donar a otras organizaciones benéficas',
  PaymentDeclined: 'Pago rechazado',
  Complaint: 'Queja',
  AccountClosed: 'Cuenta cerrada',
  AlreadyDonating: 'Ya estoy donando',
  NoReason: 'Ninguna razón dada',
  continue_session: 'Mantenerme registrado',
  session_timeout: 'Hora de término de la sesión',
  session_message:
    'La sesión actual está a punto de caducar en <1>{{countdown}}</1> segundos.',
  continue_confirmation: '¿Te gustaria continuar?',
  master_donate_btn: 'Donar a esta campaña',
  team_donate_btn: 'Donar al equipo',
  team_member_donate_btn: 'Done a la recaudación de fondos de {{name}}',
  note: 'Nota:',
  bg_image_note:
    'Esta imagen aparece como fondo en las páginas de recaudación de fondos. Está parcialmente cubierto por el Componente de Estadísticas de Recaudación de Fondos (Cuadro) en pantallas del tamaño de un escritorio, y aproximadamente un 90% cubierto en tamaños de pantalla más pequeños.',
  sample_campaign_stats:
    'Este es un componente de estadísticas de recaudación de fondos de muestra para una demostración de cómo aparecerá la imagen de fondo en una página de recaudación de fondos.',
  preview: 'Avance',
  donation_through: 'La donación se hizo a través de',
  donation: 'Donación',
  view_fundraising_page: 'Ver página de recaudación de fondos',
  how_often_donate: '¿Con qué frecuencia le gustaría donar?',
  select_amount_donate: 'Seleccione la cantidad que le gustaría dar:',
  direct_your_gift: '¿Cómo le gustaría dirigir su don?',
  once: 'Una vez',
  monthly: 'Mensual',
  annually: 'Anualmente',
  why_donate_monthly: '¿Qué impacto tiene una donación mensual?',
  eft: 'EFT',
  bank: 'Banco',
  bank_check: 'Cheque bancario',
  bank_name: 'Nombre del banco',
  acc_no: 'Número de cuenta',
  routing_no: 'Número de institución financiera y de tránsito (8 dígitos)',
  chequing: 'chequing',
  savings: 'ahorros',
  select_acc_type: 'Seleccione el tipo de cuenta',
  acc_no_validation: 'Número de cuenta se requiere',
  routing_no_validation:
    'Número de institución financiera y de tránsito se requiere',
  bank_name_validation: 'Se requiere el nombre del banco',
  Completed: 'Terminado',
  Terminated: 'Cancelado',
  Failed: 'Ha fallado',
  TransactionRefund: 'Reembolso',
  InProgress: 'En curso',
  notPaymentScheduleMessage: 'No hay calendario de pagos disponible',
  notPaymentMethodsMessage: 'No hay forma de pago disponible',
  Amount: 'Monto',
  Name: 'Nombre',
  Team: 'Equipo',
  gift_aid: 'Ayuda gratuita',
  donor_details: 'Detalles del donante',
  payment_details: 'Detalles del pago',
  Total: 'Total',
  other_btn: 'Otro',
  anonymous_checkbox: 'Hacer mi donación anónima',
  your_donation: 'Tu donación <1>{{currency}}</1>',
  your_monthly_donation: 'Tu donación mensual <1>{{currency}}</1>',
  your_annually_donation: 'Tu donación anual <1>{{currency}}</1>',
  your_membership: 'Su membresía <1>{{currency}}</1>',
  include_membership_checkbox:
    'Incluir mi membresía en mi donación <1>{{donation}}</1> <2>{{donationFrequency}}</2>.',
  share_generosity: 'Comparta su generosidad e inspire a otros:',
  help_further: '¿Quieres ayudar más?',
  get_involved_title: 'Involucrarse',
  set_up_fundraising_btn: 'Configurar mi campaña de recaudación de fondos',
  start_fundraising_accordian_title: 'Apoya una campaña',
  donateMonthlyLink: 'Me gustaría donar mensualmente',
  donateAnnuallyLink: 'Me gustaría donar anualmente',
  enter_amount: 'Por favor ingrese la cantidad',
  selection_label: 'Otro',
  order_label: 'Por favor seleccione',
  membership_order_label: 'Por favor seleccione (opcional)',
  support_display_all:
    'Por favor muestre mi nombre, mensaje y monto de la donación.',
  support_hide_amount:
    'Por favor muestre solo mi nombre y mensaje (oculta el monto de la donación).',
  support_hide_name:
    'Por favor, muestre el monto de mi donación y el mensaje solamente (oculta el nombre).',
  support_hide_all:
    'No, por favor oculte mi nombre, mensaje y monto de la donación.',
  greatest_need: 'Mayor necesidad',
  background_image_alt_text: 'imagen de tema de fondo dinámico',
  summary_link_description: `Haga clic en 'Su donación' para cambiar el monto de su donación`,
  unexpected_error_message:
    'Se ha producido un error. Pero su transacción ha sido procesada y aparecerá en su estado de cuenta. <1>No lo vuelvas a intentar.</1><br /><br /><2>Haga clic aquí</2> o comuníquese con nuestro equipo de servicios para donantes de inmediato para informarle que recibió este mensaje.',
  applePay_error: `Apple Pay solo está disponible cuando está habilitado con dispositivos compatibles con iOS (iPhone / iPad / Mac).`,
  googlePay_error:
    'GooglePay no está habilitado ni disponible en este dispositivo.',
  additionalInfo: 'información adicional',
  additional_info_msg:
    'Si tiene algún comentario relacionado con su donación, inclúyalo aquí.',
  notes_or_direction: 'Notas o dirección',
  readMore: 'Lee mas',
  showLess: 'Muestra menos',
  isCompanyGift: '¿Esto es una donación corporativa',
  yes: 'Sí',
  no: 'No',
  tribute_type: '¿Quieres que te enviemos una tarjeta?',
  no_sendTribute: 'No\ngracias',
  email_sendTribute: 'si,\npor correo\nelectronico',
  mail_sendTribute: 'si,\npor correo',
  addressee_name: 'Enviar tarjeta a',
  addressee_name_validation: `Por favor ingrese el nombre del destinatario`,
  required_pwd: 'Por favor, introduzca su contraseña',
  pwd_match:
    'Debe contener 8 caracteres, uno en mayúscula, uno en minúscula, un número y un carácter de caso especial',
  MissionCRM_label: 'Etiqueta de misión CRM',
  recaptcha_token_error:
    'No se pudo recuperar el token reCAPTCHA en este momento. Inténtelo de nuevo más tarde.',
  recaptcha_failure:
    'Solicitud incorrecta, token de recaptcha incorrecto. Por favor verifique su información e inténtelo nuevamente.',
};
