import * as React from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '@app/RootState';
import { useLocation } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import { setProgressIndicator } from '@modules/donation/donation.slice';
import { DonationPageDetails } from '@modules/donation/types';
import useStyles from './progressIndicator.styles';

export type Props = {
  page?: DonationPageDetails;
};

const ProgressIndicator: React.FC<Props> = ({ page = null }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const startPath = pathname.split('/').pop() === '';
  const stateProgressIndicator: string = useSelector(
    (state: RootState) => state.donation.progressIndicator
  );

  const [viewedStep, addViewedStep] = React.useState<Array<string>>(
    startPath ? ['donate'] : []
  );

  const steps = [
    {
      section: 'donate',
    },
    {
      section: 'contact',
    },
    {
      section: 'payment',
    },
    {
      section: 'confirmation',
    },
  ];

  if (page && page.showGiftAid && !page.showMembership) {
    steps.splice(1, 0, { section: 'giftAid' });
    if (page.showDescription) steps.splice(3, 0, { section: 'additionalInfo' });
  } else if (page && page.showMembership && !page.showGiftAid) {
    steps.splice(2, 0, { section: 'membership' });
    if (page.showDescription) steps.splice(3, 0, { section: 'additionalInfo' });
  } else if (page && page.showGiftAid && page.showMembership) {
    steps.splice(1, 0, { section: 'giftAid' });
    steps.splice(3, 0, { section: 'membership' });
    if (page.showDescription) steps.splice(4, 0, { section: 'additionalInfo' });
  } else if (
    page.showDescription &&
    !page.showGiftAid &&
    !page.showMembership
  ) {
    steps.splice(2, 0, { section: 'additionalInfo' });
  }

  React.useEffect(() => {
    if (startPath) {
      dispatch(setProgressIndicator('donate'));
    }
    if (!startPath) {
      addViewedStep([...viewedStep, pathname.split('/').pop()]);
    }
  }, [pathname, stateProgressIndicator]);

  const progress = steps.map((step) => (
    <Col key={step.section} className={`${classes.stepCol} d-flex`}>
      <div
        className={`${classes.step} ${
          stateProgressIndicator === step.section ? classes.activeStep : ''
        } ${viewedStep.includes(step.section) ? classes.viewedStep : ''}`}
      />
    </Col>
  ));

  return <Row className={classes.progressIndicator}>{progress}</Row>;
};

export default ProgressIndicator;
