// FRENCH CANADIAN (fr_CA)

export default {
  // START Aria translation
  // aria_enter: 'Beginning donation section',
  // aria_flow:
  // 'Starting {{routeName}} section. Section {{sectionIndex}} of {{totalSteps}}',
  input_amount_field_name: `Champ Montant d'entrée`,
  aria_input_amount_field:
    'Champ de saisie : saisissez le montant du don personnalisé (au dollar près)',
  aria_amount_summary: 'Montant de la commande Résumé',
  aria_member_summary: `Résumé du montant de l'adhésion`,
  aria_help_icon: 'Icône Informations/Aide',
  aria_required: 'Obligatoire',
  aria_designation_button: 'Désigner pour',
  aria_designation_selection_label: 'Sélectionnez une autre désignation',
  aria_year: `Année d'expiration à 2 chiffres`,
  aria_month: `Mois d'expiration à 2 chiffres`,
  aria_security_code: `Code de sécurité de la carte de crédit. Code à 3 ou 4 chiffres figurant au dos de votre carte`,
  aria_unexpected_error_link:
    'Veuillez cliquer ici et contacter immédiatement notre équipe des services aux donateurs.',
  // END Aria translation
  internatl_error_500: `500 - Une erreur de serveur interne s'est produite. Veuillez contacter notre équipe immédiatement`,
  stripe_promise_error: `Échec du chargement de Stripe Promise`,
  loading: 'Chargement...',
  loading_image: "Chargement de l'image...",
  verification: 'Vérification en cours...',
  timeout: `Une erreur de temporisation s'est produite. Veuillez réessayer plus tard.`,
  company: `Nom de l'entreprise`,
  company_valication: `Veuillez saisir le nom de l'entreprise`,
  company_help_text:
    "Le nom de la Société ou de l'Organisation qui apparaîtra sur l'accusé de réception et/ou le reçu.",
  employer: 'Mon employeur',
  salutation: 'Salutation',
  first_name: 'Prénom',
  first_name_validation: "Entrez votre prénom s'il vous plait",
  last_name: 'Nom',
  last_name_validation: 'Veuillez entrer votre nom de famille',
  email: 'Courriel',
  email_validation: "L'adresse e-mail n'est pas correct",
  email_help_text:
    'Votre accusé de réception et/ou reçu fiscal vous sera envoyé par e-mail ici.',
  phone: 'Téléphone',
  phone_validation:
    'Veuillez entrer un numéro de téléphone valide sans décimales ni tirets',
  address: 'Adresse',
  address2: 'Adresse 2',
  address_validation: 'Veuillez entrer votre adresse',
  suite: 'Suite',
  city: 'Ville',
  city_validation: 'Veuillez entrer votre ville',
  province: 'Province',
  province_validation: 'Veuillez entrer votre province',
  postal_code: 'Code Postal',
  postal_code_validation: 'Veuillez entrer un code postal valide',
  country: 'Pays',
  country_validation: 'Veuillez entrer un pays valide',
  amount_raised: 'Montant collecté',
  goal: 'Objectif',
  dedication_checkbox:
    "Oui, mon don est en l'honneur ou à la mémoire de quelqu'un",
  honour_dedication: "En l'honneur de...",
  memory_dedication: 'En mémoire de...',
  show_honour_message:
    "Vous avez indiqué que vous faites ce don en l'honneur de quelqu'un",
  show_memory_message:
    "Vous avez indiqué que vous faites ce don à la mémoire de quelqu'un",
  honouree_label: 'Veuillez entrer son nom',
  honouree_validation: "Merci d'entrer un nom valide",
  recipient_email: `Veuillez saisir l'adresse e-mail du destinataire`,
  special_message: 'Partagez votre message spécial ici. (Optionnel)',
  acknowledgment_checkbox:
    'Oui, je souhaite envoyer une notification de mon cadeau',
  recipient_email_validation:
    "Veuillez saisir l'e-mail ou l'adresse physique du destinataire",
  special_message_validation: 'Veuillez saisir un message pour le destinataire',
  employer_matches_checkbox: 'Mon employeur égalera les dons',
  honour: 'Honneur',
  memory: 'Mémoire',
  anonymousHelptext:
    "Cela masquera votre nom et les informations du donateur de toutes les communications publiques; cependant, l'organisme de bienfaisance recevra toujours vos informations.",
  giftAid_checkbox:
    "Oui, j'aimerais ajouter une aide-cadeau de {{gift}} (25% du don) en plus de mon don de {{donation}}.",
  month: 'Mois',
  year: 'An',
  privacy_policy: 'Politique de confidentialité',
  terms_conditions: 'Termes et conditions',
  minimum_amount: 'Montant minimal',
  donate_btn: 'Faire un don',
  start_p2p_btn: 'Commencer la collecte de fonds',
  next_btn: 'Continuer',
  prev_btn: 'Précédent',
  thank_you: 'Merci, {{name}}!',
  donation_received: 'Votre don a été reçu.',
  // new DP confirmation -
  confirmationDetails_thank_you: 'Merci, {{name}}',
  confirmationDetails_donation_received:
    'Votre don de <1>{{donationAmount}}</1> a été reçu.',
  confirmationDetails_receipt:
    'Un accusé de réception sera envoyé à <em>{{email}}</em>.',
  // -   // new DP confirmation
  donate_once: 'Faire un\ndon une fois',
  donate_monthly: 'Faire un\ndon mensuel',
  donate_annually: 'Faire un\ndon annuellement',
  finish_btn: 'Terminer',
  cancel_btn: 'Annuler',
  redirectMessage: 'Vous serez redirigé vers:',
  seconds: 'secondes',
  processing: 'Traitement de votre don ...',
  processingEP: 'Traitement de votre inscription ...',
  processingCD: 'Mise à jour de vos informations de contact ...',
  card_number: 'N° de carte',
  card_validation: "Merci d'indiquer un numéro de carte de crédit valide",
  expiry_date_mm: 'MM',
  expiry_date_yy: 'AA',
  expiry_date_mmyy: 'MM/AA',
  expiry_date_mm_validation:
    "Merci d'indiquer une date d'expiration valide (MM). Insérer la valeur numérique du mois en ajoutant un zéro aux valeurs à un chiffre.",
  expiry_date_yy_validation:
    "Merci d'indiquer une date d'expiration valide (YY).",
  card_security_code: 'CVV',
  card_security_code_validation: 'Veuillez entrer le CVV',
  giving: 'Donner <1>{{currency}}</1>',
  share_btn: 'Partager',
  share_on: 'Partager sur',
  now_booking: 'Réserver Maintenant',
  book_tickets: 'Réserver Des Billets',
  use_existing_credit_card: 'Utiliser une carte de crédit existante',
  select: 'Sélectionner',
  card: 'Carte',
  next: 'Suivant',
  last: 'Durer',
  cardType: 'Type de carte',
  expiry: 'Expiration',
  apple_pay: 'Apple Pay',
  google_pay: 'Google Pay',
  donate: 'Faire un don',
  of_goal: "de l'objectif",
  supporters: 'Partisans',
  days_left: 'Jours Restants',
  minimum_donation_of: 'Don minimum de',
  top_fundraising_teams: 'Les meilleures équipes de collecte de fonds',
  top_individual_fundraisers: 'Les meilleurs collecteurs de fonds individuels',
  individual_subtitle:
    "Commencez votre campagne de financement individuelle dès aujourd'hui",
  team_subtitle:
    "Démarrez votre campagne de financement d'équipe dès aujourd'hui",
  campaign_title: 'Allons-y!',
  individual_fundraiser_btn: 'Campagne individuelle',
  team_fundraiser_btn: "Campagne d'équipe",
  individual_fundraiser_tab: 'Pages individuelles',
  team_fundraiser_tab: `Pages d'équipe`,
  individual_page_name_label:
    'Nom de la page de collecte de fonds individuelle',
  team_page_name_label: `Nom de la page de collecte de fonds de l'équipe`,
  team_member_page_name_label: `Nom de la page de collecte de fonds des membres de l'équipe`,
  individual_page_goal_label: 'Objectif de financement individuel',
  team_page_goal_label: `Objectif de collecte de fonds de l'équipe`,
  team_member_page_goal_label: `Objectif de collecte de fonds des membres de l'équipe`,
  campaign_name: 'Nom de la page de collecte de fonds',
  team_campaign_name: "Nom de la collecte de fonds de l'équipe",
  campaign_name_validation: 'Veuillez saisir un nom de campagne valide',
  campaign_goal: 'Objectif de collecte de fonds',
  team_campaign_goal: "Objectif de collecte de fonds de l'équipe",
  campaign_goal_validation:
    'Veuillez saisir un objectif de collecte de fonds valide',
  campaign_url: 'URL de la campagne',
  start_date: 'Date de début',
  end_date: 'Date de fin',
  create_campaign_btn: 'Créer une campagne',
  update_campaign_btn: 'Mettre à jour la campagne',
  delete_campaign_btn: 'Supprimer cette campagne',
  cancel_changes_btn: 'Annuler les modifications',
  password: 'Mot de passe',
  password_validation: "s'il vous plait entrez votre mot de passe",
  password_requirements:
    'Le mot de passe doit contenir au moins 8 caractères, une majuscule, un chiffre et une casse spéciale',
  sign_in: 'Se connecter',
  forgot_password: 'Mot de passe oublié?',
  sign_up: "S'inscrire",
  dont_have: "Vous n'avez pas de compte?",
  already_have: 'Vous avez déjà un compte?',
  go_back: 'Retourner',
  forgot_password_title: 'réinitialiser le mot de passe',
  forgot_password_btn: 'Envoyer les instructions de réinitialisation',
  download_media_kit: 'Télécharger le kit média',
  learn_to_fundraise: 'Apprenez à collecter des fonds',
  help_center: "Centre d'aide",
  days_left_to_donate: 'Jours restants pour faire un don',
  days_left_to_fundraise: 'Jours restants pour collecter des fonds',
  quick_links: 'Liens rapides',
  edit_campaign_btn: 'Modifier la campagne',
  view_campaign_btn: 'Voir la campagne',
  share_my_page_btn: 'Partager ma page',
  team_campaigns: "Campagnes d'équipe",
  your_campaigns: 'Vos campagnes',
  activity: 'Activité',
  settings: 'Réglages',
  birthday: 'Anniversaire (facultatif)',
  save_changes: 'Sauvegarder les modifications',
  edit_profile_title: 'Modifier / mettre à jour le profil',
  profile_photo: 'Photo de profil',
  upload_photo: 'Envoyer la photo',
  manage_prefs_title: 'Gérer les préférences',
  donation_updates: 'Mises à jour sur les dons et événements à venir',
  phone_campaigns: 'Campagnes téléphoniques',
  updates_via_mail: 'Mises à jour par courrier',
  old_password: 'ancien mot de passe',
  new_password: 'nouveau mot de passe',
  old_password_validation: 'Veuillez entrer votre ancien mot de passe',
  new_password_validation: 'Veuillez saisir votre nouveau mot de passe',
  change_password_title: 'Changer le mot de passe',
  edit_profile: 'Editer le profil',
  my_profile: 'Mon profil',
  edit_campaign: 'Modifier la campagne',
  log_out: 'Se déconnecter',
  start_a_campaign_btn: 'Lancer une campagne',
  campaign_photo: "Image d'arrière-plan de la collecte de fonds",
  recommended_photo_size: 'Taille de photo recommandée: 1240 x 600 pixels',
  mission_statement: 'Énoncé de mission',
  characters_remaining: '{{count}} caractères restants',
  edit_campaign_title_individual:
    'Modifier votre page de collecte de fonds individuelle',
  edit_campaign_title_team: "Modifier votre page de collecte de fonds d'équipe",
  edit_campaign_title_team_member:
    'Modifier la page de collecte de fonds des membres de votre équipe',
  deletion_confirmation_question:
    'Voulez-vous vraiment supprimer cette campagne?',
  join_team_btn: "Rejoins l'équipe",
  join_team_subtitle: 'Rejoignez {{name}}',
  donated_by: 'Don de',
  campaign_supporters: 'Soutiens de campagne',
  display_name: "Nom d'affichage (facultatif)",
  leave_message: 'Laisser un message de soutien (facultatif)',
  hide_name_checkbox: 'Ne pas afficher mon nom sur la page Campagne',
  show_support_title: 'Souhaitez-vous partager un message de soutien?',
  creating_campaign: 'Création de votre campagne ...',
  failed_campaign_creation: 'Échec de la création de la campagne',
  join_team_checkbox: "Autoriser d'autres personnes à rejoindre votre équipe",
  credentials_error: 'Adresse e-mail ou mot de passe invalide',
  email_exists: 'Cette adresse email a déjà été enregistrée',
  about_campaign: 'À propos de la campagne',
  teams: 'Les équipes',
  team_members: "Membres de l'équipe",
  participants: 'Les participants',
  anonymous: 'Anonyme',
  fundraising_team_for: 'Équipe de financement pour :',
  fundraising_for: 'Collecte de fonds pour:',
  fundraising_team_member: 'Membre de:',
  no_team_members:
    "Personne ne s'est inscrit en tant que membre de cette équipe.",
  fundraising_dates: 'Dates de collecte de fonds',
  return_back_btn: 'Retour en arrière',
  changes_saved_btn: 'Changements sauvegardés!',
  no_changes_btn: 'Aucune modification à enregistrer',
  password_reset_success:
    'Les instructions de réinitialisation du mot de passe ont été envoyées à {{email}}.',
  total_amount_raised: 'Montant total collecté',
  amount: '<1>{{amount}}{{currencySymbol}}</1>',
  individual_member_of: 'Membre individuel de :',
  team_member_of: "Membre de l'équipe de :",
  role_team_member: "(Rôle : Membre de l'équipe)",
  role_team_captain: "(Rôle : Capitaine d'Equipe)",
  no_individual_pages:
    "Vous n'avez aucune page de collecte de fonds individuelle.",
  no_team_pages: "Vous n'avez aucune page de collecte de fonds d'équipe.",
  no_pages_to_display: 'Aucune page de collecte de fonds à afficher.',
  join_as_individual_btn: "Rejoindre en tant qu'individu",
  create_a_team_btn: 'Créer une équipe',
  expired: 'expiré',
  share_on_facebook: 'Partager sur Facebook',
  share_on_twitter: 'Partager sur Twitter',
  share_on_linkedin: 'Partager sur LinkedIn',
  failure_message: 'Échec du traitement du paiement',
  search: 'Chercher',
  constituentNumber: 'Numéro du constituant',
  contact_not_found: 'Constituant introuvable',
  failed_password_change: "L'ancien mot de passe ne correspond pas",
  success_password_change: 'Mot de passe mis à jour avec succès',
  lifetimeGiving: 'Durée de vie',
  givingHistory: "Donner de l'histoire",
  thisYearGiving: 'Cette année',
  lastGiftAmount: 'Montant du dernier cadeau',
  recurringGift: 'Cadeau récurrent',
  creditCard: 'Carte de crédit',
  childRecurringGift: `Statut de cadeau récurrent de l'enfant`,
  membership: 'Adhésion',
  fetching_pages: 'Récupération des pages de collecte de fonds ...',
  title_404: '404',
  subtitle_404: "Désolé, nous n'avons pas trouvé votre page.",
  message_404: 'Ramène-moi à -',
  home_page: "Page d'accueil",
  update_contact_info: 'Mettre à jour les informations de contact',
  update_payment_info: 'Mettre à jour les informations de paiement',
  update_payment_schedule: 'Mettre à jour le calendrier de paiement',
  update: 'Mettre à jour',
  update_saved_message: 'La mise à jour a bien été enregistrée',
  new_gift_btn: 'Nouveau cadeau',
  new_gift_title: 'Créer un nouveau cadeau',
  create_new_contact_btn: 'Créer un cadeau pour un nouveau donateur',
  cancel_donation: 'Annuler le don',
  back: 'Retour',
  start_by: 'Commencez par entrer un',
  or: 'Ou',

  FeltPressure: 'Le donateur a ressenti la pression de la collecte de fonds',
  NoLongerAfford: 'Je ne peux plus me permettre',
  UnderMinimumWage: `Sous l'âge minimum`,
  SpouseObjected: 'Le conjoint a objecté',
  ChangedMind: 'Esprit changé',
  IntendedShortTerm: 'Donateur destiné à un don à court terme',
  IntendedOneTime: `Le donateur avait l'intention de faire un don unique`,
  Deceased: 'Décédé',
  Misunderstood: 'Engagement mal compris',
  OtherCharities: `Faire un don à d'autres organismes de bienfaisance`,
  PaymentDeclined: 'Paiement refusé',
  Complaint: 'Plainte',
  AccountClosed: 'Compte fermé',
  AlreadyDonating: 'Déjà un don',
  NoReason: 'Aucune raison donnée',
  continue_session: 'Gardez-moi connecté',
  session_timeout: 'Expiration de la session',
  session_message:
    "La session en cours est sur le point d'expirer dans <1>{{countdown}}</1> secondes.",
  continue_confirmation: 'Voulez-vous continuer?',
  master_donate_btn: 'Faites un don à cette campagne',
  team_donate_btn: "Faites un don à l'équipe",
  team_member_donate_btn: 'Faites un don à {{name}} Fundraiser',
  note: 'Noter:',
  bg_image_note:
    'Cette image apparaît comme arrière-plan sur les pages de collecte de fonds. Il est partiellement couvert par la composante Statistiques de collecte de fonds (encadré) sur les écrans de bureau et à environ 90% sur les écrans de plus petite taille.',
  sample_campaign_stats:
    "Voici un exemple de composant de statistiques de collecte de fonds pour montrer à quoi ressemblera l'image d'arrière-plan sur une page de collecte de fonds.",
  preview: 'Aperçu',
  //
  donation_through: 'Le don a été fait par',
  donation: 'Don',
  view_fundraising_page: 'Afficher la page de collecte de fonds',

  how_often_donate: 'À quelle fréquence souhaitez-vous faire un don?',
  select_amount_donate: `
  Sélectionnez le montant que vous souhaitez donner:`,
  direct_your_gift: 'À qui souhaitez-vous adresser votre don?',

  once: 'Une fois',
  monthly: 'Mensuel',
  annually: 'Annuellement',
  why_donate_monthly: `Quel est l'impact d'un don mensuel?`,
  eft: 'EFT',
  bank: 'Banque',
  bank_check: 'Chèque bancaire',
  bank_name: 'Nom de banque',
  acc_no: 'Numéro de compte',
  routing_no: `Institution financière et numéro de transit (8 chiffres)`,
  chequing: 'chèque',
  savings: 'des économies',
  select_acc_type: 'Sélectionnez le type de compte',
  acc_no_validation: 'Numéro de compte est requis',
  routing_no_validation: `Institution financière et numéro de transit est requis`,
  bank_name_validation: 'Le nom de la banque est obligatoire',
  //
  Completed: 'Complété',
  Terminated: 'Annulé',
  Failed: 'Échec',
  TransactionRefund: 'Rembourser',
  InProgress: 'En cours',

  notPaymentScheduleMessage: 'Aucun calendrier de paiement disponible',
  notPaymentMethodsMessage: 'Aucun mode de paiement disponible',
  Amount: 'Montant',
  Name: 'Nom',
  Team: 'Équipe',
  gift_aid: "L'aide cadeau",
  donor_details: 'Détails du donateur',
  payment_details: 'Options de paiement',
  Membership: 'Adhésion',
  Total: 'Le total',
  other_btn: 'Autre',
  anonymous_checkbox: 'Rendre mon don anonyme',
  your_donation: 'Votre don <1>{{currency}}</1>',
  your_monthly_donation: 'Votre don mensuel <1>{{currency}}</1>',
  your_annually_donation: 'Votre don annuel <1>{{currency}}</1>',
  your_membership: 'Votre adhésion <1>{{currency}}</1>',
  include_membership_checkbox:
    'Incluez mon adhésion dans mon don <2>{{donationFrequency}}</2> de <1>{{donation}}</1>.',
  share_generosity: 'Partagez votre générosité et inspirez les autres:',
  help_further: 'Voulez-vous aider davantage?',
  get_involved_title: 'Être impliqué',
  set_up_fundraising_btn: 'Mettre en place ma campagne de financement',
  start_fundraising_accordian_title: 'Soutenez une campagne',
  donateMonthlyLink: "J'aimerais faire un don mensuel",
  donateAnnuallyLink: "J'aimerais faire un don chaque année",
  enter_amount: 'Veuillez saisir le montant',
  selection_label: 'Autre (veuillez sélectionner votre région)',
  order_label: 'Sélectionner',
  membership_order_label: 'Veuillez sélectionner (facultatif)',
  support_display_all:
    'Veuillez afficher mon nom, mon message et le montant de mon don.',
  support_hide_amount:
    'Veuillez afficher uniquement mon nom et mon message (masque le montant du don).',
  support_hide_name:
    'Veuillez afficher le montant de mon don et mon message uniquement (masque le nom).',
  support_hide_all:
    'Non, veuillez masquer mon nom, mon message et le montant de mon don.',
  greatest_need: 'Le plus grand besoin',
  background_image_alt_text: "image de thème d'arrière-plan dynamique",
  summary_link_description:
    'Cliquez sur << Votre don >> pour modifier le montant de votre don',
  unexpected_error_message: `Une erreur est survenue. Mais votre transaction a été traitée et apparaîtra sur votre relevé. <1>N'essayez plus.</1><br /><br /><2>Cliquez ici</2> ou contactez immédiatement notre équipe de services aux donateurs pour vous informer que vous avez reçu ce message.`,
  applePay_error: `Apple Pay n'est disponible que lorsqu'il est activé avec des appareils compatibles iOS (iPhone/iPad/Mac).`,
  googlePay_error:
    'GooglePay no está habilitado ni disponible en este dispositivo.',
  additionalInfo: 'Information additionnelle',
  additional_info_msg:
    'Si vous avez des commentaires concernant votre don, veuillez les inclure ici.',
  notes_or_direction: 'Notes ou direction',
  pion_additional_info_msg: 'Vos commentaires ici',
  pion_notes_or_direction: `Tapez le nom du missionnaire si vous ne connaissez pas le numéro de compte, ou si vous avez d'autres commentaires :`,
  halifaxyarmouth_additional_info_msg:
    'Veuillez ajouter le nom de votre paroisse',
  halifaxyarmouth_notes_or_direction: `Veuillez ajouter le nom de votre paroisse.`,
  readMore: 'Lire la suite',
  showLess: 'Montrer moins',
  isCompanyGift: "C'est un don d'entreprise",
  yes: 'Oui',
  no: 'Non',
  tribute_type: 'Souhaitez-vous que nous vous envoyions une carte ?',
  no_sendTribute: 'Non,\nmerci',
  email_sendTribute: 'Oui,\npar e-mail',
  mail_sendTribute: 'Oui,\npar courrier',
  addressee_name: 'Envoyer la carte à',
  addressee_name_validation: `Veuillez entrer le nom du destinataire`,
  required_pwd: `S'il vous plait entrez votre mot de passe`,
  pwd_match:
    'Doit contenir 8 caractères, une majuscule, une minuscule, un chiffre et un caractère spécial',
  MissionCRM_label: 'Mission Label CRM',
  recaptcha_token_error:
    'Échec de la récupération du jeton reCAPTCHA pour le moment. Veuillez réessayer plus tard.',
  recaptcha_failure:
    'Mauvaise demande, mauvais jeton recaptcha. Veuillez vérifier vos informations et réessayer.',
};
