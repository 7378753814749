import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { useHistory } from 'react-router-dom';
import { useTheme } from 'react-jss';
import { useSelector, useDispatch } from 'react-redux';
import Parser from 'html-react-parser';
import { Alert } from 'react-bootstrap';
import {
  getProcessingResult,
  createPaymentLogObject,
  setApplicationInsightsLog,
} from '@api/app';
import { DonationPageType, PageKind, SignUp } from '@root/enums';
import { setProgressIndicator } from '@modules/donation/donation.slice';
import { DonationPagePost } from '@modules/donation/types';
import { RootState } from '@app/RootState';
import { TransactionCurrency } from '@app/App';
import { setCoverCosts } from '@app/app.slice';
import { objectToFormData, getOrderTotal } from '@utils/index';
import { MissionTheme } from '@root/interfaces';
import {
  AdditionalInformation,
  ContactDetails,
  DonationAmtAndFreq,
  PageDetails,
  SelectedMembership,
} from '@app/types';
import { FrequencyType } from '@root/enums/FrequencyType';
import TagManager from 'react-gtm-module';

const { lazy, Suspense } = React;
const PaymentDetails = lazy(
  () => import('@components/organism/paymentDetails')
);
const OverlaySpinnerText = lazy(
  () => import('@components/atoms/overlaySpinnerText')
);

interface PaymentScreenProps {
  pageDetails: PageDetails;
  donationPageTypeId: DonationPageType;
  orderTotal?: number;
}

interface StateValues {
  contactDetails: ContactDetails;
  donationAmtAndFreq: DonationAmtAndFreq;
  coverCosts: boolean;
  selectedPackageId: string;
  selectedMembership: SelectedMembership;
  giftAidCheckbox: boolean;
  giftAidClaimAmount: number;
  additionalInformation: AdditionalInformation;
}

const Payment: React.FC<PaymentScreenProps> = ({ pageDetails, orderTotal }) => {
  const { t } = useTranslation();
  const transactionCurrency = React.useContext(TransactionCurrency);
  const { languageCode } = transactionCurrency;
  const dispatch = useDispatch();
  const history = useHistory();
  const theme: MissionTheme = useTheme();
  const creditCard =
    pageDetails && pageDetails.showCreditCard ? 'creditCard' : null;

  const googlePay = pageDetails && pageDetails.showGoogle ? 'googlePay' : null;

  const failureMessage =
    pageDetails &&
    pageDetails.termsOfReference &&
    pageDetails.termsOfReference.failureMessage;

  const donationPageType =
    pageDetails &&
    pageDetails.donationPageTypeId &&
    DonationPageType[pageDetails.donationPageTypeId];

  // From redux
  const {
    contactDetails,
    donationAmtAndFreq,
    coverCosts,
    selectedMembership,
    giftAidCheckbox,
    giftAidClaimAmount,
    additionalInformation,
  }: StateValues = useSelector((state: RootState) => state.app);

  const { gtmId, contactSignUp, acceptNotice } = useSelector(
    (state: RootState) => state.app
  );

  const { designationId, appealId, campaignId, packageId } = useSelector(
    (state: RootState) => state.donation
  );

  const [loading, setLoading] = React.useState(false);
  const [navigator, setNavigator] = React.useState<string>(null);
  const [submitResult, setSubmitResult] = React.useState(null);

  React.useEffect(() => {
    if (pageDetails) {
      dispatch(
        setCoverCosts(
          !!(
            pageDetails &&
            pageDetails.termsOfReference &&
            pageDetails.termsOfReference.coverCostsMessage &&
            pageDetails.showCoverCosts &&
            pageDetails.setCoverCosts
          )
        )
      );
    }
  }, []);

  React.useEffect(() => {
    if (!navigator && window && window.navigator) {
      const navigatorDetails: any = {};
      const { connection, deviceMemory } = window.navigator;
      if (connection || deviceMemory) {
        Object.assign(navigatorDetails, {
          downlink: connection.downlink || null,
          effectiveType: connection.effectiveType || null,
          onchange: connection.onchange || null,
          rtt: connection.rtt || null,
          saveData: connection.saveData || null,
          deviceMemory: deviceMemory || null,
        });
        setNavigator(JSON.stringify(navigatorDetails));
      }
    }
  }, [window && window.navigator, navigator]);

  const isRecurringPayment =
    donationAmtAndFreq && donationAmtAndFreq.givingFrequency !== 'once';
  let updatedDonationAmount =
    (donationAmtAndFreq && donationAmtAndFreq.donationAmount) || 0;

  if (selectedMembership && selectedMembership.includeMembership) {
    let membershipAmount =
      selectedMembership.membershipBaseAmount +
      selectedMembership.membershipAmountTax;
    if (donationAmtAndFreq && donationAmtAndFreq.givingFrequency !== 'once') {
      membershipAmount /= 12;
    }
    updatedDonationAmount =
      (donationAmtAndFreq &&
        donationAmtAndFreq.donationAmount - membershipAmount) ||
      0;
  }
  const membershipBaseAmount =
    (selectedMembership &&
      (isRecurringPayment
        ? selectedMembership.membershipBaseAmount / 12
        : selectedMembership.membershipBaseAmount)) ||
    0;

  const membershipAmountTax =
    (selectedMembership &&
      (isRecurringPayment
        ? selectedMembership.membershipAmountTax / 12
        : selectedMembership.membershipAmountTax)) ||
    0;

  const fullOrderTotal = getOrderTotal({
    coverCosts,
    pageDetails: pageDetails,
    donationAmount: donationAmtAndFreq && donationAmtAndFreq.donationAmount,
    selectedMembership,
    givingFrequency: donationAmtAndFreq && donationAmtAndFreq.givingFrequency,
    pageKind: PageKind.Campaign,
  });

  const onSubmit = async (
    updatedPaymentDetails: any,
    token: string,
    typeKey: string
  ) => {
    setLoading(true);

    const orderDetails: DonationPagePost = {
      donationPageId: pageDetails.pageId,
      recaptchaToken: token,
      isRecurring: isRecurringPayment,
      isSEPAPayment: false,
      organizationName: contactDetails.organizationName || '',
      myEmployer:
        contactDetails.employerMatchesDonationCheckbox &&
        contactDetails.employer
          ? contactDetails.employer
          : '',
      amount: updatedDonationAmount.toFixed(2),
      billingCity: contactDetails.address1City || '',
      billingCountry: contactDetails.address1Country || '',
      billingLine1: contactDetails.address1Line1 || '',
      billingLine2: contactDetails.address1Line2 || '',
      billingPostalCode: contactDetails.address1PostalCode || '',
      billingStateOrProvince: contactDetails.address1StateOrProvince || '',
      coverCostsCheckbox: coverCosts ? 'on' : 'off',
      costPercentage:
        pageDetails.coverCostPercentage && `${pageDetails.coverCostPercentage}`,
      costAmount:
        pageDetails.coverCostAmount && `${pageDetails.coverCostAmount}`,
      isoCurrencyCode: pageDetails.transactionCurrency.isoCurrencyCode || '',
      creditCardNo: updatedPaymentDetails.creditCardNo || '',
      expMM: updatedPaymentDetails.expMM || '',
      expYY: updatedPaymentDetails.expYY || '',
      cvc: updatedPaymentDetails.cvc || '',
      bankName: updatedPaymentDetails.bankName || '',
      bankActNumber:
        (updatedPaymentDetails.accNo &&
          updatedPaymentDetails.accNo.toString()) ||
        '',
      bankActRtNumber:
        (updatedPaymentDetails.routingNo &&
          updatedPaymentDetails.routingNo.toString()) ||
        '',
      // bankTypeCode: updatedPaymentDetails.accType || 0,
      dedicate: contactDetails.dedicate || '',
      emailAddress1: contactDetails.emailAddress1 || '',
      employerMatchesDonationCheckbox:
        contactDetails.employerMatchesDonationCheckbox ? 'on' : 'off',
      firstName: contactDetails.firstName || '',
      lastName: contactDetails.lastName || '',
      giftAidAcceptCheckbox: giftAidCheckbox ? 'on' : 'off',
      giftAidClaimAmount: giftAidCheckbox ? giftAidClaimAmount : 0,
      salutation: contactDetails.salutation || '',
      paymentNoticeCheckbox:
        acceptNotice || updatedPaymentDetails.acceptNoticeCheckbox
          ? 'on'
          : 'off',
      signUpCheckbox:
        contactSignUp === null
          ? ''
          : (contactSignUp && true && SignUp.Yes) || SignUp.No,
      telephone1: contactDetails.telephone1 || '',
      matchDonationCheckbox: contactDetails.employerMatchesDonationCheckbox
        ? 'on'
        : 'off',
      anonymousCheckbox: contactDetails.anonymousCheckbox || false,
      membershipAmount:
        (selectedMembership && selectedMembership.membershipAmount) || 0,
      membershipAmountTax: Number(membershipAmountTax.toFixed(2)),
      membershipBaseAmount: Number(membershipBaseAmount.toFixed(2)),
      membershipCategoryId:
        (selectedMembership && selectedMembership.membershipId) || '',
      payToken: updatedPaymentDetails.payToken || '',
      transactionDescription:
        (additionalInformation &&
          additionalInformation.transactionDescription) ||
        '',
      acknowledgementPaymentIdentityType:
        (isRecurringPayment && typeKey === 'ach' && 1) || 0,
    };

    // Tribute
    if (contactDetails.dedicate) {
      orderDetails.nameofHonouree = contactDetails.nameOfHonouree;
      if (contactDetails.recipientEmail) {
        orderDetails.recipientEmail = contactDetails.recipientEmail;
      }
      if (
        contactDetails.tribute &&
        (contactDetails.tribute === 'email' ||
          contactDetails.tribute === 'mail')
      ) {
        orderDetails.tributeOptionsCode =
          contactDetails.tribute === 'email' ? 703650000 : 703650001;
      }
      if (contactDetails.tributeAddressee) {
        orderDetails.tributeAddressee = contactDetails.tributeAddressee;
        orderDetails.tributeLine1 = contactDetails.tributeLine1;
        orderDetails.tributeLine2 = contactDetails.tributeLine2 || '';
        orderDetails.tributeCity = contactDetails.tributeCity;
        orderDetails.tributeStateOrProvince =
          contactDetails.tributeStateOrProvince;
        orderDetails.tributePostalCode = contactDetails.tributePostalCode;
        orderDetails.tributeCountry = contactDetails.tributeCountry;
      }

      if (contactDetails.specialMessage) {
        orderDetails.specialMessage = contactDetails.specialMessage;
      }
    }

    if (isRecurringPayment) {
      orderDetails.frequencyType =
        donationAmtAndFreq && donationAmtAndFreq.givingFrequency === 'monthly'
          ? FrequencyType.Monthly
          : FrequencyType.Annually;
    }

    if (appealId) {
      orderDetails.appealId = appealId;
    }

    if (packageId) {
      orderDetails.packageId = packageId;
    }

    if (campaignId) {
      orderDetails.campaignId = campaignId;
    }

    if (designationId) {
      orderDetails.designationId = designationId;
    }

    if (updatedPaymentDetails && updatedPaymentDetails.paymentType) {
      orderDetails.paymentType = updatedPaymentDetails.paymentType;
    }

    if (updatedPaymentDetails && updatedPaymentDetails.stripeId) {
      orderDetails.stripeId = updatedPaymentDetails.stripeId;
    }

    if (updatedPaymentDetails && updatedPaymentDetails.paymentStatus) {
      orderDetails.paymentStatus = updatedPaymentDetails.paymentStatus;
    }

    if (contactDetails.contactId) {
      orderDetails.constituentId = contactDetails.contactId;
    }

    if (
      contactDetails.organizationName !== null &&
      contactDetails.organizationName !== '' &&
      contactDetails.isCompanyGift === 'yes'
    ) {
      orderDetails.isCompanyGift = true;
    }

    const runApplicationLog = async (message) => {
      const formData: FormData = objectToFormData({
        pageId: pageDetails.donationPageId,
        message: message,
      });
      const applicationLog = await setApplicationInsightsLog(
        formData,
        PageKind[PageKind.Donation]
      );
      return applicationLog;
    };

    const runCreatePaymentLogObject = async (formData) => {
      const logResult = await createPaymentLogObject(
        formData,
        PageKind[PageKind.Donation]
      );
      return logResult;
    };

    const formData: FormData = objectToFormData(orderDetails);
    if (token != null) {
      setSubmitResult(null);
      const runPaymentProcessing = async (formData) => {
        const result = await getProcessingResult(
          formData,
          PageKind[PageKind.Donation]
        );
        return result;
      };
      runPaymentProcessing(formData)
        .then((result) => {
          if (result && result.success) {
            if (gtmId) {
              const tagPurchaseArgs = {
                dataLayer: {
                  event: 'purchase',
                  ecommerce: {
                    transaction_id: result.data,
                    affiliation: 'Mission CRM',
                    value: fullOrderTotal,
                    tax: selectedMembership
                      ? selectedMembership.membershipAmountTax
                      : 0.0,
                    currency: orderDetails.isoCurrencyCode,
                    items: [
                      {
                        item_id: 0,
                        item_name: 'Donation',
                        affiliation: 'Mission CRM',
                        currency: orderDetails.isoCurrencyCode,
                        index: 0,
                        item_brand: theme.clientFullName,
                        item_category: donationPageType,
                        item_category2: orderDetails.donationPageId,
                        item_category3: pageDetails.friendlyUrl,
                        price: updatedDonationAmount,
                        quantity: 1,
                      },
                      ...(coverCosts
                        ? [
                            {
                              item_id: 1,
                              item_name: 'Cover Cost',
                              affiliation: 'Mission CRM',
                              currency: orderDetails.isoCurrencyCode,
                              index: 1,
                              item_brand: theme.clientFullName,
                              item_category: donationPageType,
                              item_category2: orderDetails.donationPageId,
                              item_category3: 'Cover Cost',
                              price: Number(
                                (
                                  Math.round(
                                    (orderTotal - updatedDonationAmount) * 100
                                  ) / 100
                                ).toFixed(2)
                              ),
                              quantity: 1,
                            },
                          ]
                        : []),
                      ...(selectedMembership
                        ? [
                            {
                              item_id: 2,
                              item_name: 'Membership',
                              affiliation: 'Mission CRM',
                              currency: orderDetails.isoCurrencyCode,
                              index: 2,
                              item_brand: theme.clientFullName,
                              item_category: donationPageType,
                              item_category2: orderDetails.donationPageId,
                              item_category3: selectedMembership.identifier,
                              item_category4: orderDetails.membershipCategoryId,
                              price: orderDetails.membershipBaseAmount,
                              quantity: 1,
                            },
                          ]
                        : []),
                    ],
                  },
                },
                dataLayerName: 'MCRMDataLayer',
              };

              TagManager.dataLayer(tagPurchaseArgs);

              const tagTransactionArgs = {
                dataLayer: {
                  event: 'mcrm_transaction',
                  transaction: {
                    id: result.data,
                    amount: fullOrderTotal,
                    iso_currency_code: orderDetails.isoCurrencyCode,
                    payment_notice: orderDetails.paymentNoticeCheckbox,
                    payment_type: typeKey || '',
                    giving_frequency:
                      donationAmtAndFreq.givingFrequency || 'once',
                    page_kind: 'doantion page',
                    page_id:
                      orderDetails.donationPageId ||
                      orderDetails.campaignPageId,
                    appeal_id: (appealId && orderDetails.appealId) || '',
                    package_id: (packageId && orderDetails.packageId) || '',
                    campaign_id:
                      (campaignId && orderDetails.campaignId) || null,
                    designation_id:
                      (designationId && orderDetails.designationId) || '',
                    email_address_1: orderDetails.emailAddress1 || '',
                    first_name: orderDetails.firstName || '',
                    last_name: orderDetails.lastName || '',
                    dedicate: !!orderDetails.dedicate,
                    anonymous: orderDetails.anonymousCheckbox,
                    gift_aid: giftAidCheckbox,
                    cover_costs: coverCosts,
                    membership_included: !!orderDetails.membershipCategoryId,
                    is_company_gift: orderDetails.isCompanyGift || false,
                    employer_matches_donation:
                      contactDetails.employerMatchesDonationCheckbox,
                    sign_up: orderDetails.signUpCheckbox,
                  },
                },
                dataLayerName: 'MCRMDataLayer',
              };

              TagManager.dataLayer(tagTransactionArgs);
            }

            setSubmitResult(null);
            setTimeout(() => {
              setLoading(false);
              dispatch(setProgressIndicator('confirmation'));
              history.push('confirmation');
            }, 800);
          } else {
            setLoading(false);
            setSubmitResult('failed');
          }
        })
        .catch((err) => {
          console.error('Error:', err);
          dispatch(setProgressIndicator(''));

          formData.append('error', err.toString());
          formData.append('navigator', navigator);

          runApplicationLog(
            `Error: ${err.toString()} Navigator: ${navigator && navigator}`
          )
            .then(() => {
              runCreatePaymentLogObject(formData)
                .then(() => {
                  setLoading(false);
                  history.push('unexpectedError');
                })
                .catch(() => {
                  setLoading(false);
                  history.push('unexpectedError');
                });
            })
            .catch(() => {
              runCreatePaymentLogObject(formData)
                .then(() => {
                  setLoading(false);
                  history.push('unexpectedError');
                })
                .catch(() => {
                  setLoading(false);
                  history.push('unexpectedError');
                });
            });
        });
    }
  };

  return (
    <Suspense fallback={<div>{t('loading')}</div>}>
      {submitResult && (
        <Alert
          variant="danger"
          arir-label={
            (failureMessage && Parser(failureMessage)) || t('failure_message')
          }
        >
          {(failureMessage && Parser(failureMessage)) || t('failure_message')}
        </Alert>
      )}
      <OverlaySpinnerText text={t('processing')} loading={loading} />
      <GoogleReCaptchaProvider
        reCaptchaKey={pageDetails.recaptchaSiteKey}
        language={
          languageCode === 'en-CA' || languageCode === 'en-US'
            ? 'en'
            : languageCode
        }
      >
        <PaymentDetails
          page={pageDetails}
          onSubmit={onSubmit}
          paymentMethod={creditCard || googlePay}
          processingError={!!submitResult}
          orderTotal={orderTotal}
          showACH={pageDetails && pageDetails.showBank && isRecurringPayment}
        />
      </GoogleReCaptchaProvider>
    </Suspense>
  );
};
export default Payment;
