import axios, { AxiosRequestConfig } from 'axios';

export async function getClientCode() {
  const { data } = await axios.get('/home/getClientCode');
  return data.success ? data.data : 'default';
}

export async function getMembershipGroups() {
  const { data } = await axios.get('/donation/getMembershipGroups/');
  return data.success ? data.data : [];
}

export async function getAddressAutocompleteResults(
  formData: FormData,
  pageName: string
) {
  const requestOptions: AxiosRequestConfig = {
    method: 'post',
    data: formData,
    url: `/${pageName}/ValidateAddress`,
  };
  const { data } = await axios(requestOptions);
  return data.success ? data : null;
}

export async function getProcessingResult(
  formData: FormData,
  pageKind: string
) {
  const requestOptions: AxiosRequestConfig = {
    method: 'post',
    data: formData,
    url: `/${pageKind}/Process`,
  };
  const { data } = await axios(requestOptions);
  return data;
}

export async function getStripeIntentResult(formData: FormData) {
  const requestOptions: AxiosRequestConfig = {
    method: 'post',
    data: formData,
    url: `/StripePayment/StripeIntent`,
  };
  const { data } = await axios(requestOptions);
  return data.success ? data : null;
}

export async function setApplicationInsightsLog(
  formData: FormData,
  pageKind: string
) {
  const requestOptions: AxiosRequestConfig = {
    method: 'post',
    data: formData,
    url: `/${pageKind}/setApplicationInsightsLog`,
  };
  const { data } = await axios(requestOptions);
  return data.success ? data : null;
}

export async function createPaymentLogObject(
  formData: FormData,
  pageKind: string
) {
  const requestOptions: AxiosRequestConfig = {
    method: 'post',
    data: formData,
    url: `/${pageKind}/createPaymentLogObject`,
  };
  const { data } = await axios(requestOptions);
  return data.success ? data : null;
}

export async function getRecaptchaResponse(token: string, pageKind: string) {
  const { data } = await axios.get(
    `/${pageKind}/getRecaptchaResponse/?token=${token}`
  );
  return data.success ? data.data : [];
}
