import * as React from 'react';
import { Row, Col } from 'react-bootstrap';
import { useTheme } from 'react-jss';
import { PageKind } from '@root/enums';
import { ChevronRight, ChevronLeft } from 'react-bootstrap-icons';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '@app/RootState';
import { useTranslation } from 'react-i18next';
import Button from '@components/atoms/button';
import { MissionTheme, PageDetails } from '@root/interfaces';
import { getOrderTotal } from '@root/utils';
import NumberFormatter from '@components/atoms/numberFormatter';
import useStyles from './navigation.style';

export type Props = {
  color?: string;
  type?: string;
  next?: string;
  showArrows?: boolean;
  rightBtnClass?: string;
  rightBtnText?: string;
  leftBtnText?: string;
  prev?: string;
  rightBtnDisabled?: boolean;
  leftBtnDisabled?: boolean;
  rightBtnHidden?: boolean;
  leftBtnHidden?: boolean;
  rightBtnOnClick?: any;
  leftBtnOnClick?: any;
  topMargin?: boolean;
  confirmationScreen?: boolean;
  redirectTime?: string;
  dataTestId?: string;
  uppercase?: boolean;
  pageDetails?: PageDetails;
};

const Navigation: React.FC<Props> = ({
  type = 'button',
  next = null,
  prev = null,
  showArrows = true,
  rightBtnClass = null,
  rightBtnText = 'next_btn',
  leftBtnText = 'prev_btn',
  rightBtnDisabled = false,
  leftBtnDisabled = false,
  rightBtnHidden = false,
  leftBtnHidden = false,
  rightBtnOnClick = () => {},
  leftBtnOnClick = () => {},
  topMargin = true,
  confirmationScreen = false,
  redirectTime = null,
  pageDetails,
  dataTestId = 'navigation',
}) => {
  const { t } = useTranslation();
  const theme: MissionTheme = useTheme();

  const { isDP } = theme;
  const { isCP } = theme;
  const classes = useStyles({ topMargin, isDP });

  const { donationAmtAndFreq, coverCosts, selectedMembership } = useSelector(
    (state: RootState) => state.app
  );
  const orderTotal = getOrderTotal({
    coverCosts,
    pageDetails: pageDetails,
    donationAmount: donationAmtAndFreq && donationAmtAndFreq.donationAmount,
    selectedMembership,
    givingFrequency: donationAmtAndFreq && donationAmtAndFreq.givingFrequency,
    pageKind: PageKind.Campaign,
  });

  const scrollToActions = () => {
    const actionsElement = document.getElementById('actions');
    if (actionsElement) {
      actionsElement.scrollIntoView(true);
    }
  };

  const amount =
    isDP && rightBtnText === 'donate' ? (
      <NumberFormatter value={orderTotal} hideLocale />
    ) : null;

  const rootProps = {
    className: classes.root,
    'data-testid': dataTestId,
  };

  return (
    <Row {...rootProps}>
      <Col
        xs={{ span: 12, order: 12 }}
        sm={{ span: isDP ? 4 : 5, order: 1 }}
        lg={{ span: isDP ? 3 : 5 }}
        xl={{ span: isDP ? 3 : 5 }}
        className={classes.prevBtn}
      >
        <Button
          type={type === 'submit' ? 'submit' : 'button'}
          onClick={() => {
            leftBtnOnClick();
            if (isCP) {
              setTimeout(scrollToActions);
            }
          }}
          disabled={leftBtnDisabled}
          hidden={leftBtnHidden}
          block={!isDP}
          to={prev}
          buttonStyles={theme.navButtons.left}
          isDP={isDP}
        >
          {showArrows && (
            <ChevronLeft aria-hidden="true" className={classes.arrowMargin} />
          )}
          {showArrows && `${'\u00A0'}`}
          {t(leftBtnText)}
        </Button>
      </Col>
      <Col
        xs={isDP ? { order: 1 } : { span: 12, order: 1 }}
        sm={
          !isDP && {
            span: 5,
            offset: 2,
            order: 1,
          }
        }
        lg={!isDP && { span: 5, offset: 2 }}
        xl={!isDP && { span: 5, offset: 2 }}
        className={`${classes.nextBtn} ${isDP ? classes.donateAmountBtn : ''}`}
      >
        {confirmationScreen ? (
          redirectTime && (
            <p className={classes.redirectText}>
              {t('redirectMessage')} <strong>{redirectTime}</strong>{' '}
              {t('seconds')}
            </p>
          )
        ) : (
          <Button
            className={rightBtnClass}
            id={amount && 'mcrmDonate'}
            type={type === 'submit' ? 'submit' : 'button'}
            onClick={() => {
              rightBtnOnClick();
              if (isCP) {
                setTimeout(scrollToActions);
              }
            }}
            disabled={rightBtnDisabled}
            hidden={rightBtnHidden}
            block={!isDP}
            to={next}
            buttonStyles={theme.navButtons.right}
            isDP={isDP}
          >
            {t(rightBtnText)}
            {amount && `${'\u00A0'}`}
            {amount}
            {showArrows && `${'\u00A0'}`}
            {showArrows && (
              <ChevronRight
                aria-hidden="true"
                className={classes.arrowMargin}
              />
            )}
          </Button>
        )}
      </Col>
    </Row>
  );
};

export default React.memo(Navigation);
