import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { DonationPageDetails } from '@modules/donation/types';
import { RootState } from '@app/RootState';
import { getOrderTotal } from '@utils/index';
import { PageKind } from '@root/enums';

const { lazy, Suspense } = React;
const ConfirmationDetails = lazy(
  () => import('@components/organism/confirmationDetails')
);

interface ConfirmationScreenProps {
  pageDetails?: DonationPageDetails;
  pageKind?: PageKind;
}

const Confirmation: React.FC<ConfirmationScreenProps> = ({ pageDetails }) => {
  const { t } = useTranslation();
  const { contactDetails, donationAmtAndFreq, coverCosts, selectedMembership } =
    useSelector((state: RootState) => state.app);

  const orderTotal = getOrderTotal({
    coverCosts,
    pageDetails: pageDetails,
    donationAmount: donationAmtAndFreq && donationAmtAndFreq.donationAmount,
    selectedMembership,
    givingFrequency: donationAmtAndFreq && donationAmtAndFreq.givingFrequency,
    pageKind: PageKind.Campaign,
  });

  const firstName = contactDetails && contactDetails.firstName;
  const emailAddress = contactDetails && contactDetails.emailAddress1;
  const donation = orderTotal;

  return pageDetails ? (
    <Suspense fallback={<div>{t('loading')}</div>}>
      <ConfirmationDetails
        page={pageDetails}
        name={firstName}
        email={emailAddress}
        donation={donation}
      />
    </Suspense>
  ) : null;
};

export default Confirmation;
