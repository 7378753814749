import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { CampaignPageDetails } from '@api/campaign';
import { ContactDetails } from '@app/types';
import { RootState } from '@app/RootState';

const { lazy, Suspense } = React;
const ConfirmationDetails = lazy(
  () => import('@components/organism/confirmationDetails')
);

interface ConfirmationScreenProps {
  pageDetails?: CampaignPageDetails;
}

const Confirmation: React.FC<ConfirmationScreenProps> = ({ pageDetails }) => {
  const { t } = useTranslation();
  const contactDetails: ContactDetails = useSelector(
    (state: RootState) => state.app.contactDetails
  );
  const firstName = contactDetails && contactDetails.firstName;

  return pageDetails ? (
    <Suspense fallback={<div>{t('loading')}</div>}>
      <ConfirmationDetails page={pageDetails} name={firstName} />
    </Suspense>
  ) : null;
};

export default Confirmation;
