import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { DonationPageType } from '@root/enums';
import { DonationPageDetails } from '@modules/donation/types';
import { CampaignPageDetails } from '@api/campaign';
import { RootState } from '@app/RootState';
import { setProgressIndicator } from '@modules/donation/donation.slice';
import { setGiftAidCheckbox } from '@app/app.slice';

const { lazy, Suspense } = React;
const GiftAidDetails = lazy(
  () => import('@components/organism/giftAidDetails')
);
const Navigation = lazy(() => import('@components/molecules/navigation'));

interface GiftAidScreenProps {
  pageDetails?: DonationPageDetails & CampaignPageDetails;
  donationPageTypeId: DonationPageType;
}

const GiftAid: React.FC<GiftAidScreenProps> = ({ pageDetails }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const isDP = pageDetails.donationPageTypeId === DonationPageType.DP;
  const isOCP = pageDetails.donationPageTypeId === DonationPageType.OCP;
  const {
    giftAidCheckbox,
    giftAidClaimAmount,
    donationAmtAndFreq,
    membershipGroups,
  } = useSelector((state: RootState) => state.app);

  const { ariaFocus } = useSelector((state: RootState) => state.donation);

  const onChange = async (receivedGiftAidCheckbox: boolean) => {
    dispatch(setGiftAidCheckbox(receivedGiftAidCheckbox));
  };

  const onSubmit = () => {
    let next = 'contact';
    if (isOCP) {
      if (
        pageDetails.showMembership &&
        membershipGroups &&
        membershipGroups.length > 0
      ) {
        next = 'membership';
      } else {
        next = 'payment';
      }
    }
    dispatch(setProgressIndicator(next));
    history.push(next);
  };

  return pageDetails ? (
    <Suspense fallback={<div>{t('loading')}</div>}>
      <GiftAidDetails
        giftAidCheckbox={giftAidCheckbox}
        giftAidClaimAmount={giftAidClaimAmount}
        donationAmount={donationAmtAndFreq.donationAmount}
        handleChange={onChange}
        page={pageDetails}
      />
      <Navigation
        pageDetails={pageDetails}
        rightBtnOnClick={onSubmit}
        prev={isDP ? '.' : 'donate'}
        leftBtnOnClick={() => {
          if (isDP) {
            dispatch(setProgressIndicator('donate'));
          }
        }}
      />
    </Suspense>
  ) : null;
};

export default GiftAid;
