import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '@app/RootState';
import { DonationPageType } from '@root/enums';
import { DonationPageDetails } from '@modules/donation/types';
import { CampaignPageDetails } from '@api/campaign';
import { setAdditionalInformation } from '@app/app.slice';
import { setProgressIndicator } from '@modules/donation/donation.slice';
import { CampaignPageTypes } from '@modules/campaign/campaignSlice';

const { lazy, Suspense } = React;
const AdditionalInfoDetails = lazy(
  () => import('@components/organism/additionalInfoDetails')
);

interface MembershipScreenProps {
  pageDetails: DonationPageDetails & CampaignPageDetails;
}
const AdditionalInfo: React.FC<MembershipScreenProps> = ({ pageDetails }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { additionalInformation } = useSelector(
    (state: RootState) => state.app
  );

  const onChange = (value: any) => {
    dispatch(
      setAdditionalInformation({
        transactionDescription: value.transactionDescription,
      })
    );
    onSubmit();
  };

  const onSubmit = () => {
    if (
      (pageDetails &&
        pageDetails.campaignPageTypeId &&
        pageDetails.campaignPageTypeId === CampaignPageTypes.Master &&
        pageDetails.enableFundraising) ||
      pageDetails.parentCampaignPageId
    ) {
      history.push('showSupport');
    } else {
      if (pageDetails.donationPageTypeId === DonationPageType.DP) {
        dispatch(setProgressIndicator('payment'));
      }
      history.push('payment');
    }
  };

  return (
    <Suspense fallback={<div>{t('loading')}</div>}>
      <AdditionalInfoDetails
        additionalInformation={additionalInformation}
        page={pageDetails}
        handleChange={onChange}
        onSubmit={onSubmit}
      />
    </Suspense>
  );
};

export default AdditionalInfo;
