import color from 'color';

import { DonationPageType, PageBackgroundType } from '@root/enums';
import {
  DonationPageThemeCollection,
  DonationPageBackgroundCollection,
  PageBackground,
} from '@root/interfaces';
import { ButtonStates } from '@root/interfaces/MissionTheme';
import defaults from './defaults';

const createDonationPageStyles = (
  backgroundInput?: DonationPageBackgroundCollection
): DonationPageThemeCollection => {
  const defaultBackground: PageBackground = (backgroundInput &&
    backgroundInput.default) || {
    value: defaults.colorBackground,
    type: PageBackgroundType.Static,
  };

  const colors = {
    colorPrimary: '#124c1b',
    colorSecondary: '#059713',
    // colorPrimary: '#059713',
    // colorSecondary: '#124c1b',
    colorTertiary: '#e0dea1',
    buttonTextColor: '#FFFFFF',
    colorBackground: '#059713',
    // colorBackground: '#124c1b',
  };

  const buttonText = { setButtonTextColor: false, color: '#FFFFFF' };

  const primaryColor = color(colors.colorPrimary);
  const secondaryColor = color(colors.colorSecondary);
  const tertiaryColour = color(colors.colorTertiary);

  // Gradients
  const defaultGradient = `linear-gradient(to right, ${primaryColor.lighten(
    0.6
  )}, ${primaryColor.lighten(0.65)})`;

  const secondaryGradient = `linear-gradient(to right, ${secondaryColor.lighten(
    0.6
  )}, ${secondaryColor.lighten(0.65)})`;

  const tertiaryGradient = `linear-gradient(to right, ${tertiaryColour.lighten(
    0.1
  )}, ${tertiaryColour.lighten(0.15)})`;

  const activeGradient = `linear-gradient(to right, ${primaryColor.hex()}, ${primaryColor.lighten(
    0.2
  )})`;
  const mutedGradient = `linear-gradient(to right, ${primaryColor.lighten(
    0.7
  )} 0%, ${primaryColor.lighten(0.9)} 100%)`;

  const defaultButton = {
    ...defaults.buttons.defaults,
    background: tertiaryGradient,
    color: colors.colorPrimary,
    borderRadius: 0,
    height: 'auto',
    fontWeight: 400,
    fontSize: '1.15rem',
  };

  const buttonStates: ButtonStates = {
    defaults: defaultButton,
    hover: {
      ...defaults.buttons.hover,
      background: '#fff',
      color: primaryColor.hex(),
      boxShadow: `0px 0px 15px 0px ${primaryColor.hex()}`,
    },
    focus: {
      ...defaults.buttons.active,
      background: `${primaryColor.hex()} ${activeGradient}`,
      color: '#fff',
      boxShadow: `none`,
    },
    active: {
      ...defaults.buttons.active,
      background: `${primaryColor.hex()} ${activeGradient}`,
      color: '#fff',
    },
    disabled: {
      ...defaultButton,
      ...defaults.buttons.disabled,
      background: 'transparent',
      border: `1px solid ${primaryColor.hex()}`,
      color: primaryColor.hex(),
    },
  };

  return {
    [DonationPageType.DP]: {
      // include defaults for all page types
      ...defaults,
      isDP: true,
      setButtonTextColor: false,
      buttonTextColor: '#FFFFFF',

      // override defaults with DP specific values
      ...colors,
      globalFontSize: 14,
      inputLabelSize: '1.15rem',
      activeGradient,
      mutedGradient,
      defaultGradient,
      buttons: buttonStates,

      // globals (Bootstrap Overrides)

      globals: {
        '.form-control': {
          fontSize: '1.15rem',
        },
        '.form-check': {
          paddingLeft: '1.75rem',
        },
        '.form-check-input': {
          position: 'absolute',
          left: '-100vw',
        },
        '.form-check .form-check-label:before': {
          content: '""',
          position: 'absolute',
          top: '.15rem',
          left: 0,
          width: 18,
          height: 18,
          border: `1px solid ${colors.colorPrimary}`,
          color: '#fff',
          textAlign: 'center',
          fontSize: 12,
        },
        '.form-check .form-check-input[type=radio] ~ .form-check-label:before':
          {
            borderRadius: '100%',
          },
        '.form-check.checked .form-check-input[type=checkbox] ~ .form-check-label:before':
          {
            content: '"\\2713"',
            background: colors.colorPrimary,
          },
        '.form-check.checked .form-check-input[type=radio] ~ .form-check-label:before':
          {
            content: '"\\2B24"',
            background: '#fff',
            color: colors.colorPrimary,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          },
      },
      navButtons: {
        left: {
          defaults: {
            background: 'none',
            padding: '10px 0px',
            textTransform: 'uppercase',
            width: '100%',
          },
          hover: {
            boxShadow: 'none',
            textDecoration: 'underline',
          },
          focus: {
            ...buttonStates.focus,
          },
          active: {
            // ...buttonStates.active,
            background: 'none',
          },
          disabled: {
            ...buttonStates.disabled,
          },
        },
        right: {
          defaults: {
            ...buttonStates.active,
            textTransform: 'uppercase',
            padding: '10px 0px',
            width: '100%',
          },
          hover: {
            ...buttonStates.hover,
          },
          focus: {
            ...buttonStates.focus,
          },
          active: {
            ...buttonStates.active,
          },
          disabled: {
            ...buttonStates.disabled,
          },
        },
      },

      // Inputs
      inputs: {
        ...defaults.inputs,
        defaults: {
          ...defaults.inputs.defaults,
          borderColor: colors.colorPrimary,
          borderRadius: 0,
        },
        active: {
          ...defaults.inputs.active,
          borderBottom: `4px solid ${colors.colorPrimary}`,
          boxShadow: `0px 0px 15px 0px ${primaryColor.hex()}`,
        },
        valid: {
          ...defaults.inputs.valid,
          borderBottom: `4px solid ${colors.colorPrimary}`,
        },
        error: {
          ...defaults.inputs.error,
          borderColor: '#dc3545',
          border: `3px solid #dc3545 !important`,
        },
      },

      // Input amount field
      inputAmountField: {
        ...defaults.inputAmountField,
        defaults: {
          ...defaults.inputAmountField.defaults,
          background: activeGradient,
          borderRadius: 0,
          borderColor: 'transparent',
          color: '#fff',
          height: 39,
        },
        active: {
          ...defaults.inputAmountField.active,
          background: defaultGradient,
        },
        // NOT IN USE
        // disabled: {
        //   background: mutedGradient,
        //   color: colors.colorPrimary,
        //   fontStyle: 'italic',
        //   opacity: 0.7,
        // },
      },

      // Dropdown
      dropdown: {
        ...defaults.dropdown,
        defaults: {
          background: tertiaryGradient,
          color: `${
            buttonText.setButtonTextColor
              ? buttonText.color
              : colors.colorPrimary
          }`,
          fontWeight: 400,
          fontStyle: 'italic',
          borderColor: 'transparent',
          borderRadius: 0,
          fontSize: '1.15rem',
        },
        active: {
          ...defaults.dropdown.active,
          color: 'white',
          fontWeight: 400,
          borderColor: 'transparent',
          background: activeGradient,
          fontStyle: 'normal',
        },
        valid: {
          ...defaults.dropdown.valid,
          background: activeGradient,
        },
        disabled: {
          ...defaults.dropdown.disabled,
          background: 'transparent',
          color: colors.colorPrimary,
          borderColor: 'transparent',
          fontWeight: 400,
        },
      },

      // add backgrounds for specific screens
      backgrounds: {
        default: defaultBackground,
        donate:
          (backgroundInput && backgroundInput.donate) || defaultBackground,
        contact:
          (backgroundInput && backgroundInput.contact) || defaultBackground,
        giftAid:
          (backgroundInput && backgroundInput.giftAid) || defaultBackground,
        membership:
          (backgroundInput && backgroundInput.membership) || defaultBackground,
        payment:
          (backgroundInput && backgroundInput.payment) || defaultBackground,
        confirmation:
          (backgroundInput && backgroundInput.confirmation) ||
          defaultBackground,
        additionalInfo:
          (backgroundInput && backgroundInput.additionalInfo) ||
          defaultBackground,
      },
    },
    [DonationPageType.OCP]: {
      ...defaults,
    },
  };
};

export default createDonationPageStyles;
