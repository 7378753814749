import * as React from 'react';
import cx from 'classnames';
import {
  FormGroup,
  FormControl,
  FormControlProps,
  FormGroupProps,
  Col,
} from 'react-bootstrap';
import useStyles from './selectBox.style';

interface Option {
  label: string;
  value: any;
}

interface Props extends FormControlProps {
  options: Option[];
  placeholder?: string;
  // eslint-disable-next-line no-unused-vars
  onBlur?: (e?: any) => void;
  onClick?: (e?: any) => void;
  groupProps?: FormGroupProps;
  name?: string;
  md?: any;
  sm?: any;
  xs?: any;
}

const SelectBox: React.FC<Props> = (props) => {
  const { value, placeholder, options, md, sm, xs, groupProps, ...rest } =
    props;
  const classes = useStyles({
    isCol: groupProps && groupProps.as && groupProps.as === Col,
  });

  const groupClasses = cx(classes['select-group'], {
    [classes['has-value']]: !!value,
  });

  return (
    <FormGroup className={groupClasses} md={md} sm={sm} xs={xs} {...groupProps}>
      <FormControl
        value={value}
        as="select"
        {...rest}
        className={classes['select-box']}
      >
        {placeholder ? (
          <option
            value=""
            hidden
            className={classes['select-option']}
            aria-hidden="true"
          >
            {placeholder}
          </option>
        ) : null}
        {options && options.length > 0
          ? options.map((option, index) => (
              <option
                key={index.toString()}
                aria-label={option.label}
                value={option.value}
                className={classes['select-option']}
              >
                {option.label}
              </option>
            ))
          : null}
      </FormControl>
    </FormGroup>
  );
};

export default SelectBox;
